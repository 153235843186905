.blog__header-wrapper{
    background-color: $primary-light;
    border-radius: 20;
    padding: 10px 20px 10px 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size:contain;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    height: 400px;
   
}
.blog-wrapper {
    .blog-card {
        display: flex;
        flex-direction: column;
        box-shadow: 0 3px 7px -1px rgba(#000, .1);
        margin-bottom: 1.6%;
        background: $white;
        line-height: 1.4;
        font-family: sans-serif;
        border-radius: 20px;
        overflow: hidden;
        z-index: 0;
      
        .tag {
          color: inherit;
          &:hover {
            color: $primary;
          }
        }
        &:hover {
          .photo1 {
            transform: scale(1.3) rotate(3deg);
          }
          .photo2 {
            transform: scale(1.3) rotate(3deg);
          }
          .photo3 {
            transform: scale(1.3) rotate(3deg);
          }
        }
        .meta {
          position: relative;
          z-index: 0;
          height: 200px;
        }
        .photo1 {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-size: cover;
          background-position: center;
          transition: transform .2s;
        }
        .photo2 {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-size: cover;
            background-position: center;
            transition: transform .2s;
          }
          .photo3 {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-size: cover;
            background-position: center;
            transition: transform .2s;
          }
          .photo4 {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-size: cover;
            background-position: center;
            transition: transform .2s;
          }
        .details,
        .details ul {
          margin: auto;
          padding: 0;
          list-style: none;
        }
      
        .details {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -100%;
          margin: auto;
          transition: left .2s;
          background: rgba(#000, .6);
          color: $white;
          padding: 10px;
          width: 100%;
          font-size: .9rem;
          a {
            text-decoration: dotted underline
          }
          ul li {
            display: inline-block;
          }
          .author:before {
            font-family: FontAwesome;
            margin-right: 10px;
            content: "\f007";
          }
      
          .date:before {
            font-family: FontAwesome;
            margin-right: 10px;
            content: "\f133";
          }
      
          .tags {
            ul:before {
              font-family: FontAwesome;
              content: "\f02b";
              margin-right: 10px;
            }
            li {
              margin-right: 2px;
              &:first-child {
                margin-left: -4px;
              }
            }
          }
        }
        .description {
          padding: 1rem;
          background: $white;
          position: relative;
          z-index: 1;
          h1,
          h2 {
            font-family: Poppins, sans-serif;
          }
          h1 {
            line-height: 1;
            margin: 0;
            font-size: 1.7rem;
          }
          h2 {
            font-size: 1rem;
            font-weight: 300;
            color: $dark;
            margin-top: 5px;
          }
          .read-more {
            text-align: right;
            a {
              color: $primary;
              display: inline-block;
              position: relative;
              &:after {
                content: "\f061";
                font-family: FontAwesome;
                margin-left: -10px;
                opacity: 0;
                vertical-align: middle;
                transition: margin .3s, opacity .3s;
              }
      
              &:hover:after {
                margin-left: 5px;
                opacity: 1;
              }
            }
          }
        }
        p {
          position: relative;
          margin: 1rem 0 0;
          &:first-of-type {
            margin-top: 1.25rem;
            &:before {
              content: "";
              position: absolute;
              height: 5px;
              background: $pink;
              width: 35px;
              top: -0.75rem;
              border-radius: 3px;
            }
          }
        }
        &:hover {
          .details {
            left: 0%;
          }
        }
      
      
        @media (min-width: 640px) {
          flex-direction: row;
          .meta {
            flex-basis: 40%;
            height: auto;
          }
          .description {
            flex-basis: 60%;
            &:before {
              transform: skewX(-3deg);
              content: "";
              background: #fff;
              width: 30px;
              position: absolute;
              left: -10px;
              top: 0;
              bottom: 0;
              z-index: -1;
            }
          }
          &.alt {
            flex-direction: row-reverse;
            .description {
              &:before {
                left: inherit;
                right: -10px;
                transform: skew(3deg)
              }
            }
            .details {
              padding-left: 25px;
            }
          }
        }
      }
}

.blog__detail-wrapper{
  margin-top: 30px;
  overflow: hidden; 
  text-align: justify;
  margin-bottom: 30vh;

   .detail-image {
    float: left !important; 
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
  }
  .detail-text {
   clear: left;
   line-height: 35px;
   letter-spacing: 0.2px;
   color: $dark;
  }
  .detail-title{
    font-size: 28px;
    font-weight: 600;
    color: $primary-dark;
  }
  .detail-titlesup{
    font-size: 32px;
    font-weight: 400;
    color: $primary;

  }
}
.filter__modal-section-inner{
  overflow-y: scroll;
  max-height: 300px;
}
.guide__filter-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: $white;
  border-radius: 8px;
  padding: 10px 16px 10px 16px;

  .form-search {
    width: 350px !important;
    border: 2px solid $pink-light !important;
    padding: 10px 24px 10px 65px;
    border-radius: 20px;
    background-image: url("https://www.sehrimbu.com/images/topbar-search.svg");
    background-position: 24px 5px;
    background-repeat: no-repeat;
    outline: none !important;
    height: 40px;

    @media (max-width: 600px) {
      width: 100% !important;
    }

    &::-webkit-input-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &::-moz-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &:-ms-input-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &:-moz-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }
  }

}

@media (max-width: 960px){
  .detail-title {
    font-size: 22px !important;
  }
  .guide__filter-top{
    gap: 8px !important;
  }
  .blog-card {
    .description {
      h1 {
        font-size: 22px !important;
      }
    }
  }
}