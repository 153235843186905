.product__list-container {
	display: grid;
	grid-gap: 24px;
	grid-template-rows: auto;
	grid-template-columns: repeat(2, minmax(0, fr));
	grid-auto-flow: dense;
	margin-top: 8px;
	margin-top: var(--ds-spacing-03);
	padding: 0 8px;
	padding: 0 var(--ds-spacing-03);
	background-color: $white;

	.product__list-wrapper {
		position: relative;
		border: 1px solid #f0f1f2;
		border-radius: 20px;

		.product__info-wrapper {
			position: relative;


			.product__image-pb {
				padding-bottom: 10%;
				.product__image-wrapper {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					&:hover {
						.image-back {
							opacity: 1;
						}

						.image-front {
							opacity: 0;
						}
					}

					.image-back {
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						bottom: 0;
						opacity: 0;
						transition: opacity .5s;

					}

					.image-front {
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						bottom: 0;
						opacity: 1;
						transition: opacity .5s;

					}

					.product__image-resize--config {
						height: 120%;
						border-radius: 20px;
						min-height: 280px ;

					}

					@supports ((-o-object-fit:contain) or (object-fit:contain)) and ((-o-object-fit:cover) or (object-fit:cover)) {
						.styles__contain--3ElI7 .product__image-resize--config {
							transform: none;
							position: static;
							width: 100%;
							height: 100%;
						}
					}

				}
			}
		}

		.product-edit-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 33px;
			height: 33px;
			background-color: rgba(239, 251, 250, 0.5);
			color: $primary;
			border: 1px solid lighten($color: $primary, $amount: 10);
			border-radius: 20px;
			position: absolute;
			right: 5px;
			top: 5px;

			&:hover {
				background-color: $primary;
				color: $white;
			}
		}

		.product-delete-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 33px;
			height: 33px;
			background-color: rgba(255, 206, 210, 0.5);
			color: $red;
			border: 1px solid lighten($color: $red, $amount: 15);
			border-radius: 20px;
			position: absolute;
			right: 5px;
			top: 45px;

			&:hover {
				background-color: $red;
				color: $white;
			}
		}
		.product-discount-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 33px;
			height: 33px;
			border: 1px solid lighten($color: $green, $amount: 15);
			background-color: lighten($color: $green, $amount: 30);
			color: $green;
			border-radius: 20px;
			position: absolute;
			right: 5px;
			top: 85px;
			&:hover {
				background-color: $green;
				color: $white;
			}
		}

	}
}
.product-description-container{
	word-wrap: break-word !important;
	position: relative;
	z-index: 100;
}

.product-down {
	padding-top: 155px;
	padding-left: 14px;
}

.store-down {
	padding-top: 20px ;
	padding-left: 0px ;
}

.filter-visible-smallscreen {
    display: none;
}

@media only screen and (max-width: 991px) {
	.product-edit-icon {
		width: 26px !important;
		height: 26px !important;
		font-size: 12px !important;
		right: 14px !important;
	}
	.product-down{
		padding-top: 150px !important;
	}
	.product__list-wrapper{
		margin-bottom: 1rem;
	}
	.product__list-container{
		grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
	}

	.product-delete-icon {
		width: 26px !important;
		height: 26px !important;
		font-size: 12px !important;
		right: 14px !important;
	}

	.product-list-wrapper {
		justify-content: center !important;
		padding-left: 0rem !important;

	}

	.filter-unvisible-smallscreen {
		display: none;
	}

	.filter-visible-smallscreen {
		display: flex;
	}
}

@media (min-width: 480px) {
	.product__list-container {
		grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
	}
}

@media (max-width: 476px) {
	.product-down {
		padding-top: 30px;
		padding-left: 20px;
	}

	.product__list-container {
		display: grid;
		grid-gap: 16px;
		grid-template-rows: auto;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-auto-flow: dense;
		margin-top: 8px;
		margin-top: var(--ds-spacing-03);
		padding: 0 8px;
		padding: 0 var(--ds-spacing-03);
	}
}

.product__image-small-preview {
	&:hover {
		border: 2px solid $primary !important;
	}
}

.product__image-small-preview.active {
	border: 2px solid $primary !important;

}

.product__list-image {
	.slick-dots {
		position: absolute;
		left: 50%;
		bottom: 15px !important;
		transform: translate(-40%, 0%);
		margin: 0 auto;
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;

		border-radius: 50px;
		background-color: #b1b0b0;
		opacity: 0.5;
		backdrop-filter: blur(6px);
		width: fit-content !important;
		height: 15px;

	}
}

.activity_page{

  .form-search {
    width: 350px !important;
    border: 2px solid $pink-light !important;
    padding: 10px 24px 10px 65px;
    border-radius: 20px;
    background-image: url("https://www.sehrimbu.com/images/topbar-search.svg");
    background-position: 24px 5px;
    background-repeat: no-repeat;
    outline: none !important;
    height: 40px;

    @media (max-width: 600px) {
      width: 100% !important;
    }

    &::-webkit-input-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &::-moz-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &:-ms-input-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &:-moz-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }
  }

}
.places__list-section {
  .form-search {
    width: 350px !important;
    border: 2px solid $pink-light !important;
    padding: 10px 24px 10px 65px;
    border-radius: 20px;
    background-image: url("https://www.sehrimbu.com/images/topbar-search.svg");
    background-position: 24px 5px;
    background-repeat: no-repeat;
    outline: none !important;
    height: 40px;

    @media (max-width: 600px) {
      width: 100% !important;
    }

    &::-webkit-input-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &::-moz-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &:-ms-input-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &:-moz-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }
  }
}