
.basket-card {
    border-radius: 10px !important;
    .basket-card-header {
        background-color: #F4F8FB;
        box-shadow: none;
        border-radius: 10px 10px 0px 0px;
        border: none !important;
    }
    .basket-card-body{
        box-sizing: border-box;
        flex-direction: column;
        height: 100%;
        box-shadow: none;
        border-radius: 3px;
        border-radius: 10px;
        .basket-product-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

       }
       input[type=number]::-webkit-inner-spin-button {
       border: 2px solid red;
      }
}

.basket-product-checkout-btn {
    border-radius: 0px 0px 10px 10px !important;
}


@media (max-width: 576px) {
    .piece-count {
        .minus-icon {
            width: 25px !important;
            height: 26px !important;
        }
        .product-count {
            width: 26px !important;
            height: 26px !important;
        }
        .plus-icon {
            width: 25px !important;
            height: 26px !important;
        }
    }
    }
.piece-count {
    margin-top: 10px !important;
    .minus-icon {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border: 1px solid #e6e6e6;
        background-color: #fafafa;
        width: 24px;
        height: 34px;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        font-size: 10px;
        &:hover {
            color: $red;
        }
    }
    .product-count {
        width: 42px;
        user-select: none;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #4a4a4a;
        box-sizing: border-box;
        border: none;
        border-top: solid 1px #e6e6e6;
        border-bottom: solid 1px #e6e6e6;
        background-color: #ffffff;
        outline: none;
        text-align: center;
    }
    .plus-icon {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border: 1px solid #e6e6e6;
        background-color: #fafafa;
        width: 24px;
        height: 34px;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        cursor: pointer;
        font-size: 10px;
        &:hover {
            color: $primary;
        }
    }

}
.checkout-without-login{
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 35px;
    color: $primary;
    cursor: pointer;
    &:hover{
        color: darken($color: $primary, $amount: 20%);
    }
}
.checkout-signup-bck{
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    .badge{
        display: flex;
        justify-content: center;
        align-items: center;
    }
 
}

.order-summary-mobile {
    background-color: $primary-light;
    border-top:2px solid $primary;
    position: fixed;
    width: 100%;
    bottom: 0;
    color: white;
    font-size: 25px;
    z-index: 1001;
   
      
}
.basket-card-mobile{
    height: 100%;
    .product-count{
        width: 45px;
    }
}

.discount__code-container{
    margin-top: 10px;
    // button:disabled,
    // button[disabled]{
    // background-color: rgba(15, 130, 121, 0.5) !important;
    // border: none !important;
    // }
}