@media (max-width: 576px) {
    .modal-header {
        padding-left: 20px;
        padding-right: 20px;
    }
   
    
  
}

.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 20px;
            border: none;

            .modal-header {
                border: none;

                .modal-title {
                    font-size: 22px;
                    font-weight: 500;
                }

                .close {
                    font-weight: 300;
                    font-size: 40px;
                    background-color: transparent;
                    border: none !important;
                    color: $muted ;
                }
            }
        }
    }

    .share-product-modal {
        align-items: flex-end;
        width: 100% !important;
        min-width: 100%;
        padding: 0 !important;
        margin: 0 !important;
        width: 100vh;
        height: 100vh;
        background-color: white;
        .modal-body{
            margin-bottom: 20px;
            overflow-y: scroll;
        }
        .modal-header {
            border-bottom: 0.7px solid $muted !important;
            margin-left: 0px;
            margin-right: 20px;
            .modal-title {
                font-size: 16px !important;
                padding-left: 20px;
                
            }
            padding: 0;
            .close {
                padding-right: 10px;
                font-size: 30px;
            }
        }
        .modal-content {
            border-radius: 0 !important;
            height: 100%;
        }
    }
}
.order__3dsecurity-modal{
    .modal-header {
        padding: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        height: 50px;
    }
        .close {
            padding-right: 10px;
            font-size: 24px;
        }
    }
  
    .image__preview-modal{
        .modal-content {
            width: 100% !important;
        }
        .modal-header {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1001;
        }
        padding: 0 !important;
        .modal-body {
            padding: 0 !important;
            border-radius: 20px;
        }
    }
    .comment__image-preview{
        .modal-content {
            width: 97% !important;
        }
        .modal-header {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1001;
            padding: 0 ;
            padding-right: 5px;
        }
        padding: 0 !important;
        .modal-body {
            padding: 0 !important;
            border-radius: 20px;
        }
    }

 .advertising__banner-modal{
    .modal-header{
        top: -15px !important;
        padding: 0 !important;
    }
        .close {
            color: #ffffff !important;
            font-size: 56px !important;
        }
    }