
.sales-static-card {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    .card-body{
        background-image: linear-gradient(#F9FAFE, #F4F8FB);
        
    }
    .line-chart{
        background-color: #d1eeea;
        border-radius: 20px;
        margin-top: 1.5rem;
        width: 100%;
      }
  
}
.my_store {
    .filter-date-picker{
        width: 275px;
        min-width: 275px;
        position: relative;
        .date__icon-container{
          
            position: absolute;
            right: 0;
            top: 0;
            margin-right: 3px;
            margin-top: 3px;
            .date__icon-container{
                background-color: $primary;
                border-radius: 20px;
                width: 35px;
                height: 35px;
                color: $white;
                display: flex;
                align-items: center ;
                justify-content: center;
            }
        }

        .form-control {
            background-image: linear-gradient(#F9FAFE, #F4F8FB);
        }
    }
}

.wallet-frame {
    position: relative;
    color: $dark;
    background-image: linear-gradient(#F9FAFE, #F4F8FB);
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 57px;
.wallet-frame__inner{
    background-color: #d1eeea;
    border-radius: 20px;
}
    &::after {
        content: "";
        position: absolute;
        display: block;
        height: 10px;
        bottom: -10px;
        /* -height */
        left: 0;
        right: 0;
        /* TODO Add browser prefixes */
        background: linear-gradient( 45deg, transparent 33.333%,  $primary-light 33.333%,  $primary-light 66.667%, transparent 66.667%), linear-gradient( -45deg, transparent 33.333%,  $primary-light 33.333%,  $primary-light 66.667%, transparent 66.667%);
        background-size: 20px 40px;
        /* toothSize doubleHeight */
        background-position: 0 50px;
    overflow: hidden;

        border-radius: 20px;
    }

    .total-amount__container {
        border-bottom: 1px dashed $white;
    }
  }
 

  