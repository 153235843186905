.tabs {
  overflow: auto;

  .nav-tabs {
    li {
      .nav-link {
        padding: 8px 20px;
        margin-right: 0px;
        transition: background-color 0.3s;
        cursor: pointer;
        color: $content;

        &:hover,
        &:focus,
        &:active {
          background-color: #f5f5f5;
          border-color: #fafbfe #fafbfe #ddd;
        }
      }

      .nav-link.active,
      .nav-link.active:focus,
      .nav-link.active:hover {
        background-color: transparent;
        border-color: #dddddd;
        border-bottom-color: #fff;
        color: $primary !important;
      }

      &.disabled .nav-link {
        color: #999999;
      }
    }
  }

  &.tabs--bordered-bottom {
    .nav-tabs {
      .nav-link {
        border-color: transparent;
        border-bottom: 1px solid transparent;
        padding-bottom: 8px;
      }

      .nav-link.active {
        &,
        &:focus,
        &:hover {
          border-radius: 0;
          border-color: transparent;
          border-bottom: 2px solid $primary-light;
        }
      }
    }
  }

  &.tabs--justify {
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;

      li {
        width: 100%;

        a {
          text-align: center;
        }
      }
    }
  }
}

.nav-segment-secondary {
  position: relative;
  background-color: #f8f9fc;
  padding: 0.25rem 0.25rem;
  border-radius: 0.3125rem;

  .nav-link {
    color: #8492a6;
    padding: 0.4375rem 0.65625rem;
    border-radius: 0.3125rem;
    &.active {
      color: #1e2022;
      background-color: #fff;
      box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
    }
  }
}

.nav-segment-primary {
  position: relative;
  background-color: darken($light, 1%);
  border-radius: 0.3125rem;

  .nav-link {
    color: #8492a6;
    border-radius: 0.3125rem;

    &.active {
      background: #2f55d4;
      h6 {
        color: #fff !important;
      }
    }
  }
}

.tabs-full-width {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  background-color: rgba(248, 249, 252, 0.3);
  border: 1px solid rgba(90, 109, 144, 0.1) !important;

  .container {
    height: 100%;
  }

  .nav {
    height: 100%;
    justify-content: space-around;
  }

  .nav-link {
    display: flex;
    height: 100%;
    align-items: center;

    h6 {
      color: #161c2d;

      &:hover {
        color: #2f55d4;
      }
    }
    &.active {
      border-bottom: 2px solid $primary;
      h6 {
        color: #2f55d4;
      }
    }
  }
}