.ticket__detail-modal{
padding: 0 !important;
position: relative;
.ticket__detail_qr{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
}
.activity_image--container-modal {
    height: 100%;
    max-height: 720px;
}
.order-nav-tabs {
    background-color: #fff;
    border-radius: 20px;

    .nav-item:nth-child(1) {
        border-radius: 20px 0px 0px 20px;
    }

    .nav-item:nth-child(2) {
        border-radius: 0px 20px 20px 0px;
    }

    .nav-item.active_bottom {
        border-bottom: 3.5px solid $primary !important;
        border-right: 1px solid $primary !important;
        border-left: 1px solid $primary !important;
        border-top: 1px solid $primary !important;
        transition: 0.2s;

    }

    li {
        .nav-link {
            .order-nav-title {
                height: 100%;
                font-size: 18px;
                font-weight: 500;
                color: $content-light;
                text-align: center;
                transition: background-color 0.3s;
                background-color: transparent;
                border-radius: 20px;
                color: $dark;
                cursor: pointer;
                padding-bottom: 0.5rem !important;

                &:hover,
                &:focus,
                &:active {}

                &.nav-link-xs {
                    padding: 8px 10px;
                    text-align: start;
                }
            }

            .info-wrapper {
                color: $dark;
                font-size: 14px;
                font-weight: 400;
            }

        }

        .nav-link.active,
        .nav-link.active:focus,
        .nav-link.active:hover {
            .order-nav-title {
                color: $primary !important;

            }
        }

        &.disabled .nav-link {
            color: #999999;
        }
    }

    .react-select__control {
        .react-select__placeholder {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.order__threeDSecurity-form {
    background-color: $white;
    margin-top: 0;
    z-index: 1001;
    width: 100%;
}

.order__success-card-wrapper {
    height: 100vh;
    margin-top: 0;
    position: absolute;
    z-index: 1001;
    width: 100%;
    background: white;
    top: 0;

    .order__success-body {
        display: flex;
        justify-content: center;
        position: relative;
        top: 30%;
    }
}

.past__order-table {
    .past-order-card {
        border-radius: 10px;
        width: 100%;
        .activity__entry_btn{
            width: 175px;
            @media (max-width: 768px) {
                width: 100% !important;
                margin-top: 12px;
            } 
        }
        .order__info-cardbody {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
          
            align-items: start;
            gap: 24px;

            @media (max-width: 990px) {
                width: 750px;
            }

            @media (max-width: 768px) {
                width: 100%;
                display: block;
            }
            .order__info-image-container{
                width: 185px;
                height: 175px;
                min-width: 185px;
                border-radius: 8px;
                @media (max-width: 768px) {
                    width: 100%;
                }
                
            }
         
            .order__info-image-container-ticket{
                width: 185px;
                height: 175px;
                min-width: 185px;
                border-radius: 8px;
                @media (max-width: 768px) {
                    
                }
            }
            .order__info-inner{
                display: flex;
                flex-direction: column;
                gap: 8px;
                height: 100%;
                justify-content: space-between;
            }
            .ticket_status{
                background-color: $success;
                color: white;
                border-radius: 20px;
                padding: 8px 16px 8px 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
            }
            .order__info-status {
                width: 200px;

                @media (max-width: 990px) {
                    width: 150px;
                }

                @media (max-width: 768px) {
                    width: 140px;
                }
            }

            .order__info-image {
                width: 575px;

                @media (max-width: 990px) {
                    width: 400px;
                }

                @media (max-width: 768px) {
                    width: 300px;
                }
            }

            .order__info-button {
                width: 150px;
                margin: auto;
            }
        }
      
    }
    .product_info {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }
    .material-table__wrap {
        background-color: transparent;
        padding: 0;

        .card-header {
            background-color: $gray-100;
        }
    }
}

.order__detail-cardbody {
    border-radius: 0px 0px 20px 20px !important;
}




.past__sales-store-table {
    .card-haeder {
        background-color: transparent !important;
        border: none;
    }

    .past-order-card {
        border-radius: 10px;
        width: 100%;


        .store__order-cardbody {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

          

            .store__info-image {
                width: 100%;
                // overflow-x: scroll;
                // @media (max-width: 500px) {
                //     width: 360px;
                // }

            }

            .order__info-button {
                width: 150px;
                margin: auto;
            }

            .wallet-gift {
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .store__order-status {
            position: absolute;
            right: 0;
            margin-top: -35px;
            margin-right: 20px !important;
        }

    }

    .material-table__wrap {
        background-color: transparent;
        padding: 0;

        .card-header {
            background-color: $gray-100;
        }
    }
}




.card-stretch-full3 {
    height: calc(100% - .5rem);

}

.store__earn-info {
    position: absolute;
    right: 0;
    top: 0;

    .compaigns-card {
        background-color: $primary-light;
        border-radius: 0px 10px 0px 20px !important;
        border: none !important;
        width: 100%;
        border-bottom: 3px solid rgba(7, 65, 59, 0.5) !important;
        .card-body {
            text-align: center;
            padding: 10px 20px 10px 20px;
        }

        .rocket-icon {
            color: $primary;
            font-size: 22px;
        }
    }
}

.shipping__price-info-container {
    background-color: $primary-light;
    display: flex;
    align-items: center;
    border-radius: 8px;
}