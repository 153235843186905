.my__store-tabs {
  ::-webkit-scrollbar {
    width: 0em;
    height: 0em;
  }
  .nav {
    flex-wrap: nowrap !important;
    width: 100%;
    overflow-y: scroll;
  }
  .nav-tabs {
    width: 100%;
    overflow-x: scroll;
    flex-wrap: inherit;
    border-bottom: 0;
    align-items: center;
    border: 0 !important;

    .nav-item-custom {
      color: $dark;
      margin-right: 20px;

      .nav-link {
        border-radius: 20px;
        border: 1px solid $primary !important;
        color: $dark;
        margin-bottom: 5px;
        width: 150px ;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border-radius: 20px !important;
          border: 0 !important;
          color: $white !important;
          background-color: $primary;
          padding-bottom: 10px !important;
        }
      }
      .nav-link.active {
        &,
        &:focus,
        &:hover {
          border-radius: 20px !important;
          border: 0 !important;
          color: $white !important;
          background-color: $primary;
          padding-bottom: 10px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}



  .store-type-container {
      display: flex;
      margin-top: 20px;
      .store-type-wrapper {
          display: flex;
          position: relative;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

        }
        
        .store-type-wrapper input {
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .checkmark {
          top: 0;
          left: 0;
          height: 60px;
          width: 220px;
          background-color: transparent;
          border: 1px solid $muted;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 12px;
          font-weight: 500;
           border-radius: 50px !important;
          .checkmark-icon{
            background-color: $primary-light-dark;
            border-radius: 50px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
           i{
            color: $primary-dark;
           } 

           &:hover{

           }
          
          }
        }
      
        .store-type-wrapper input:checked ~ .checkmark {
         border: 1.5px solid $primary
         
        }
        
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }
        
        .store-type-wrapper input:checked ~ .checkmark:after {
          display: block;
        }
        
        .store-type-wrapper .checkmark:after {
          left: 2px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: none !important;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
  }
 
@media (max-width: 992px) {
  .store-type-container {
    flex-wrap: wrap;
    .card-body {
      padding: 0 !important;
    }
    .store-type-wrapper {
        font-size: 14px;
        width: 100% !important;
        .form-check {
          width: 100% !important;

        }
      }
      .checkmark {
        height: 45px;
        width: 100% !important;
      }
 
}

    }

    .discount-settings-modal{
      .discount__settings-container{
        // background-image: url('../discount_ticket.png');
        // width: 100%;
        // height: 300px;
        // max-height: 1000px;
        // background-size: contain;
        // background-repeat: no-repeat;
        // background-position: center center;
        // max-height: 1000px;
      }
    }