.profile-tab.col-xl-4 {
    @media (min-width: 1200px) {
        flex: 0 0 auto;
        width: 27%;
    }
}

.profile-page.col-xl-8 {
    @media (min-width: 1200px) {
        flex: 0 0 auto;
        width: 73%;
    }
}

.profile-tab {
    margin-top: 1rem;

    .card {
        background-color: #fdfdfd;

        .card-header {
            background-color: #fcfcfc;
            border: none !important;
            margin-right: 1rem;
            margin-left: 1rem;
        }

        .profile-card-footer {
            padding: 20px 30px 0px 30px;
        }
    }

    .tabs {
        overflow: auto;
        margin-top: 70px;

        .nav-tabs {
            width: 100%;
            overflow-x: auto;
            border-bottom: none !important;
            flex-wrap: wrap !important;
            display: block !important;

            li {
                .nav-link {
                    height: 100%;
                    font-size: 14px;
                    font-weight: 500;
                    color: $content-light;
                    text-align: center;
                    padding: 8px 45px;
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-top: 14px;
                    transition: background-color 0.3s;
                    background-color: $primary-light;
                    border-radius: 20px;
                    color: $primary;
                    cursor: pointer;
                    padding-top: 0.9rem !important;
                    padding-bottom: 0.9rem !important;

                    &:hover,
                    &:focus,
                    &:active {}

                    &.nav-link-xs {
                        padding: 8px 10px;
                        text-align: start;
                    }
                }

                .nav-link.active,
                .nav-link.active:focus,
                .nav-link.active:hover {
                    color: #ffff !important;
                }

                &.disabled .nav-link {
                    color: #999999;
                }
            }
        }

        &.tabs--bordered-bottom {
            .nav-tabs {
                .nav-link {
                    border-color: transparent;
                    padding-bottom: 14px;
                }

                .nav-link.active {

                    &,
                    &:focus,
                    &:hover {
                        border-bottom: none !important;
                        background-color: $primary !important;
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}

.profile-avatar-upload {
    position: relative;
    overflow: hidden;

    &:before {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        border-radius: 0;
        opacity: 0.8;
        transition: 0.5s ease;
        width: 100%;
        height: 40px;
        bottom: 0;
        left: 0;
        background-color: $primary;
        color: #cebfe9;

    }

    span {
        font-size: 0;
    }

    &:hover {
        span {
            font-size: 14px;
            position: absolute;
            top: 120px;
            padding: 0;
            color: #c8badc;

        }
    }

    &.profile-avatar-upload-sm {
        &:before {
            height: 28px;
        }
    }

    &:hover {
        &:before {
            font-size: 30px;
            height: 100%;
            background-color: $primary-light;
        }
    }

    .photo-upload {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        cursor: pointer;

    }
}

.address-card-wrapper {
    background-color: #fff;
    border-radius: 20px;

    .card {
        .card-header {
            border-radius: 20px 20px 0px 0px;
        }

        .card-footer {
            background-color: transparent;
            border: none;
        }
    }
}

.address-create-form {
    .react-select {
        .react-select__menu-notice--no-options {
            font-size: 12px;
        }

        .react-select__control {
            .react-select__placeholder {
                font-size: 12px !important;
                padding-bottom: 4px;
            }

            .react-select__indicator-separator {
                margin-bottom: 7px !important;
            }
        }
    }

    .form__form-group-label {
        font-size: 12px;
        font-weight: 600;
    }
}

.add-new-card {
    &:hover {
        .add-new-card-inner {
            background-color: $red !important;

        }

        i {
            color: #ffff !important;
        }

        span {
            color: #ffff !important;
        }
    }
}

.past-order-card:first-child {
    margin-top: 0 !important;
}

.bill-type-container {

    .bill-type-container {
        display: flex;
        margin-top: 20px;
     
        .bill-type-wrapper {
            display: flex;
            position: relative;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          
          .bill-type-wrapper input {
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
        
          .checkmark {
            top: 0;
            left: 0;
            height: 60px;
            width: 200px;
            background-color: transparent;
            border: 1px solid $muted;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
                border-radius: 50px !important;
          
          }
        
          .bill-type-wrapper input:checked ~ .checkmark {
           border: 2px solid #2196F3
           
          }
          
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          
          .bill-type-wrapper input:checked ~ .checkmark:after {
            display: block;
          }
          
          .bill-type-wrapper .checkmark:after {
            left: 2px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: none !important;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
    }
   
}
@media (max-width: 768px) {
    .checkmark{
        width: 146px !important;
       }  }


.rate-product-card {

    .rate-image-upload {
        input {
    display: none;
    
        }
    }

    .card-body {
        border-radius: 10px !important;
        background-color: #f7f7f7;
        &:hover {

        .add-new-card-inner {
                background-color: $primary !important;
            }
        }
        .remove-icon {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 16px;
            margin-top: 10px;
            margin-right: 5px;
            color: $dark;
            background-color: $white;
            border: 1px solid $content;
            border-radius: 100px !important;
            &:hover {
                color: $red;
                background-color: $soft-red;
                border: 1px solid $soft-red;

                transition: 0.2s;

            }

        }
    }
}

.profile-mobile-container {
    .profile-user-details {
        i{
            filter: drop-shadow(3px 3px 3px rgba(231, 84, 128, 0.5));
        }
    }
}
.profile-base-mobl-container {
    i{
        filter: drop-shadow(3px 3px 3px rgba(231, 84, 128, 0.5));
    }
}