//
// components.scss
//

//component-wrapper
.component-wrapper {
  .title {
    font-size: 20px;
  }

  &.sticky-bar {
    li {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0px !important;
      }

      a {
        transition: all 0.5s ease;

        &:hover,
        &:focus {
          color: $primary !important;
        }
      }
    }
  }
}

//Icon
@each $name,
$value in $colors {
  .icon-#{$name} {
    background-color: transparent !important;
    border: none !important;
    color: #{$value} !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      color: darken($value, 10%) !important;
    }
  }

}

//Buttons
@each $name,
$value in $colors {
  .btn-#{$name} {
    background-color: #{$value} !important;
    border: 1px solid #{$value} !important;
    color: $white !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($value, 10%) !important;
      border-color: darken($value, 10%) !important;
      color: $white !important;
    }
  }

  .btn-soft-#{$name} {
    background-color: rgba($value, 0.1) !important;
    border: 1px solid rgba($value, 0.1) !important;
    color: #{$value} !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: #{$value} !important;
      border-color: #{$value} !important;
      color: $white !important;
    }
  }

  .btn-outline-#{$name} {
    border: 1px solid #{$value};
    color: #{$value};
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active {
      background-color: #{$value};
      border-color: #{$value};
      color: $white !important;
    }
  }

  .btn-focus-none {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }


}



.btn {
  padding: 10.5px 30px !important;
  border-radius: 20px !important;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
  border-radius: 6px;

  &.btn-corner-right {
    border-radius: 0px 20px 0px 20px !important;
  }

  &.btn-corner-left {
    border-radius: 20px 0px 20px 0px !important;
  }
  &.btn-corner-left-top {
    border-radius: 0px 0px 20px 0px !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.btn-lg {
    padding: 14px 35px !important;
    font-size: 14px;
  }

  &.btn-md {
    padding: 9px 30px !important;
    font-size: 14px;
  }

  &.btn-sm {
    padding: 8px 20px !important;
    font-size: 12px !important;
  }

  &.btn-xs {
    padding: 6px 15px !important;
    font-size: 10px !important;
  }


  &.btn-pills {
    border-radius: 30px;
  }

  &.btn-light {
    color: $dark !important;
    border: 1px solid $gray-300 !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($light, 10%) !important;
      color: $dark !important;
    }
  }

  &.btn-soft-light {
    color: rgba($dark, 0.5) !important;
    border: 1px solid $gray-300 !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      color: $dark !important;
    }
  }

  &.btn-outline-light {
    border: 1px solid $gray-300 !important;
    color: $dark !important;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: $light !important;
    }
  }

  &.btn-icon {
    height: 36px;
    width: 36px;
    line-height: 34px;
    padding: 0;
    color: black !important;
    border: none !important;

    .icons {
      height: 16px;
      width: 16px;
      font-size: 16px;
    }

    &.btn-xsm {
      height: 26px;
      width: 26px;
      line-height: 0px;

      .icons {
        height: 14px;
        width: 14px;
        font-size: 14px;
      }
    }

    &.btn-sm {
      height: 30px;
      width: 30px;
      line-height: 28px;
      font-size: 14px;
    }

    &.btn-lg {
      height: 48px;
      width: 48px;
      line-height: 46px;

      .icons {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }
    }
  }
}

button:not(:disabled) {
  outline: none;
}

//Shadow
.shadow {
  box-shadow: $shadow !important;
}

.shadow-xs {
  box-shadow: $shadow-s;
}

.shadow-s {
  box-shadow: $shadow-s;
}

.shadow-lg {
  box-shadow: $shadow-lg !important;
}

.shadow-md {
  box-shadow: $shadow-md !important;
}

.shadow-auth {
  box-shadow: 0 0 50px rgba(46, 42, 84, 10%) !important;
}

//Alert
@each $name,
$value in $colors {
  .alert-#{$name} {
    background-color: rgba($value, 0.9);
    color: $white;
    border-color: $value;

    .alert-link {
      color: darken($value, 30%);
    }
  }

  .alert-outline-#{$name} {
    background-color: $white;
    color: $value;
    border-color: $value;
  }
}

.alert {
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;

  &.alert-light {
    background-color: lighten($light, 3%);
    color: $dark;
    border-color: $gray-200;
  }

  &.alert-dismissible {
    padding-right: 4rem;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      color: inherit !important;
      width: auto !important;
      height: auto !important;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.6;
      padding: 0.4rem 1.25rem;

      &:focus,
      &:hover {
        text-decoration: none;
        opacity: 0.8;
      }
    }
  }

  &.alert-pills {
    border-radius: 30px;
    display: inline-block;

    .content {
      font-weight: 600;
    }
  }
}

//Breadcrumb
.page-next-level {
  .title {
    font-size: 28px;
    letter-spacing: 1px;
    border-radius: 5rem;
  }

  .page-next {
    position: relative;
    top: 110px;
    z-index: 99;

    @media (max-width: 768px) {
      top: 70px;
    }
  }
}

.breadcrumb {
  letter-spacing: 0.5px;
  padding: 8px 24px;
  display: block;

  .breadcrumb-item {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding-left: 0;
    display: inline-block;

    a {
      color: $dark;

      &:hover {
        color: $primary;
      }
    }

    &.active {
      color: $primary;
    }

    &:before {
      content: "";
    }

    &:after {
      content: "\F0142" !important;
      font-size: 14px;
      color: $dark;
      font-family: "Material Design Icons";
      padding-left: 5px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

//Pagination
.pagination {
  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      background-color: #effbfa;

    }

    &:last-child .page-link {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }

    .page-link {
      color: $dark;
      border: 1px solid $gray-300;
      padding: 8px 16px;
      font-size: 14px;
      background-color: #effbfa;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        color: $white;
        background: rgba($primary, 0.9);
        border-color: rgba($primary, 0.9);
      }
    }

    &.active {
      .page-link {
        color: $white;
        background: $primary !important;
        border-color: $primary;
        cursor: not-allowed;
      }
    }
  }
}

//Avatar

.profile-avatar-upload {
  position: relative;
  overflow: hidden;

  &:before {
    font-family: FontAwesome;
    content: "\f083";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 0;
    opacity: 0.8;
    transition: 0.5s ease;
    width: 100%;
    height: 40px;
    bottom: 0;
    left: 0;
    background-color: $primary;
    color: #cebfe9;
  }
}

.avatar {
  &.avatar-xxxl {
    height: 300px;
    min-height: 300px;
    max-width: 500px;
    max-height: 300px;
    border-radius: 20px;
  }
  &.avatar-store {
    height: 300px;
    min-height: 300px;
    max-width: 250px;
    max-height: 300px;
    border-radius: 20px;
  }

  &.avatar-xxl {
    height: 150px;
    width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 150px;
  }
  &.avatar-xl-m {
    width: 125px;
    height: 125px;
    min-width: 125px;
    min-height: 125px;
    max-width: 125px;
    max-height: 125px;
  }
  &.avatar-xl {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
  }

  &.avatar-lg {
    width: 75px;
    height: 75px;
    min-width: 75px;
    min-height: 75px;
    max-width: 75px;
    max-height: 75px;
  }

  &.avatar-md {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
  &.square-avatar-xl {
    width: 125px;
    height: 145px;
    min-width: 125px;
    min-height: 145px;
    max-width: 125px;
    max-height: 145px;
    border-radius: 6px;
    img {
      position: relative;
      top: 12px;
      left: 2px;
    }
  }
  &.square-avatar-xxl {
    width: 210px;
    height: 245px;
    min-width: 210px;
    min-height: 245px;
    max-width: 210px;
    max-height: 245px;
    border-radius: 20px;

  }
  &.square-avatar-middle {
    width: 200px;
    height: 150px;
    min-width: 200px;
    min-height: 150px;
    max-width: 200px;
    max-height: 150px;
    border-radius: 20px;
  }
  &.square-avatar-3xl {
    width: 250px;
    height: 285px;
    min-width: 250px;
    min-height: 285px;
    max-width: 250px;
    max-height: 285px;
    border-radius: 20px;

  }
  &.square-avatar-lg {
    width: 75px;
    height: 95px;
    min-width: 75px;
    min-height: 95px;
    max-width: 75px;
    max-height: 95px;
    border-radius: 6px;

    img {
      position: relative;
      top: 12px;
      left: 2px;
    }
  }

  &.square-avatar-md {
    width: 65px;
    height: 75px;
    min-width: 65px;
    min-height: 75px;
    max-width: 65px;
    max-height: 75px;
    border-radius: 6px;

    img {
      position: relative;
      top: 10px;
      left: 4px;
    }
  }
  &.square-avatar-sm {
    width: 55px;
    height: 65px;
    min-width: 55px;
    min-height: 65px;
    max-width: 55px;
    max-height: 65px;
    border-radius: 6px;

    img {
      position: relative;
      top: 10px;
      left: 4px;
    }
  }

  &.rounded-square {
    border-radius: 10px;
  }
  &.rounded-square-5 {
    border-radius: 5px;
  }

  &.avatar-padding {
    background-color: #ffffff;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.avatar-border {
    border: 1px solid rgba(196, 196, 196, 0.5);
  }

  &.avatar-shadow-sm {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &.avatar-shadow {
    box-shadow: 0px 3.9px 7.8px rgba(0, 0, 0, 0.2);
  }

  &.avatar-shadow-lg {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }


}


//Accordion
.accordion {
  .accordion-item {
    overflow: hidden;

    .accordion-button {
      font-weight: 600;
      text-align: left;

      &:focus {
        box-shadow: none;
      }

      &:before {
        content: "\F0143";
        display: block;
        font-family: "Material Design Icons";
        font-size: 16px;
        color: $primary;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.5s ease;
      }

      &.collapsed {
        background-color: $white;
        color: $dark !important;
        transition: all 0.5s;

        &:before {
          top: 15px;
          transform: rotate(180deg);
          color: $dark !important;
        }
      }

      &:after {
        display: none;
      }
    }
  }
}

//Nav
.nav-pills {
  margin-bottom: 15px;
  background: darken($light, 1%);

  .nav-link {
    color: $gray-700 !important;
    padding: 5px;
    transition: all 0.5s ease;

    &.nav-link-alt {
      color: $white !important;

      &.active {
        background: $dark !important;
      }
    }

    &.active {
      background: $primary;
      color: $white !important;

      .tab-para {
        color: rgba($white, 0.65) !important;
      }
    }
  }
}

//Processbar
.progress-box {
  .title {
    font-size: 15px;
  }

  .progress {
    height: 8px;
    overflow: visible;

    .progress-bar {
      border-radius: 6px;
      animation: animate-positive 3s;
      overflow: visible !important;
    }

    .progress-value {
      position: absolute;
      top: -32px;
      right: -15px;
      font-size: 13px;
    }
  }

  @keyframes animate-positive {
    0% {
      width: 0;
    }
  }
}

//blockquote
.blockquote {
  border-left: 3px solid $gray-300;
  border-radius: 6px;
  font-size: 16px;
}

//Form
.form-label,
.form-check-label {
  font-weight: 700;
  font-size: 14px;
}

.form-label {
  width: 100%;
}

.form-check-label-normal {
  font-weight: 400;
}

.form-control {
  border: 1px solid $gray-300;
  font-size: 14px;
  line-height: 32px;
  background-color: $white !important;
  border-radius: 20px;

  &:focus {
    border-color: $primary;
    box-shadow: none;
  }

  &[readonly] {
    background-color: $white !important;
  }

  &:disabled {
    background-color: $gray-300;
  }

  &.form-control-error {
    border: 1px solid $red;
  }
}

.toolbar-filter {
  .form-control {
    line-height: 32px;
    font-size: 16px;
    width: 194.11px;

  }
}

.form-control-error-label {
  font-size: 10px;
  line-height: 13px;
  color: $red;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form-control-phone {
  width: 100% !important;
  min-width: 100% !important;
  border: 1px solid #dee2e6 !important;
  font-size: 14px !important;
  line-height: 26px;
  background-color: #ffffff !important;
  border-radius: 6px !important;
}

.form-check-input {
  &:focus {
    border-color: $primary;
    box-shadow: none;
  }

  &.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
  }
}

.form-file {
  width: 100%;
  min-height: 100px;
  position: relative;
  text-align: center;
  background-color: #fff;


  .form-file-control {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }


}

.form-control-file {
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

//Form Switch
.form-switch {
  padding-left: 0 !important;
  input[type="checkbox"] {
    & {
      width: 3em;
    height: 1.3em;
      padding: 0;
      position: relative;
      background-color: #CECFD2;
      border-radius: 20px;
      outline: none;
      cursor: pointer;
      -webkit-appearance: none;
      transition: background-color 0.5s ease, transform 0.5s ease;
      &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        width: 34%;
        height:74%;
        top: 2.5px;
        left: 3px;
        border-radius: 20px;
        background-color: #ffff;
      }
      &:checked {
        background: #86d993;

        &:before {
          content: "";
          left: auto;
          right: 3px;

        }
      }
    }
  }
}
//Table Switch
.table-switch {
  display: none;
  
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .table-switch-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }
  
  + .table-switch-btn {
    outline: 0;
    display: block;
    width: 3em;
    height: 1.3em;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 34%;
      height: 100%;
    }
    
    &:after {
      left: 0;
    }
    
    &:before {
      display: none;
    }
  }
  
  &:checked + .table-switch-btn:after {
    left: 66%;
  }
}
.table-switch-ios {
  + .table-switch-btn {
    background: #fbfbfb;
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;
    border: 1px solid #e8eae9;
    &:after {
      border-radius: 2em;
      background: #fbfbfb;
      transition:
        left .3s cubic-bezier(
          0.175, 0.885, 0.320, 1.275
        ),
        padding .3s ease, margin .3s ease;
      box-shadow:
        0 0 0 1px rgba(0,0,0,.1),
        0 4px 0 rgba(0,0,0,.08);
    }
    
    &:hover:after {
      will-change: padding;
    }
    
    &:active {
      box-shadow: inset 0 0 0 2em #e8eae9;
      &:after {
        padding-right: .8em;
      }
    }
  }
  
  &:checked + .table-switch-btn {
    background: #86d993;
    &:active {
      box-shadow: none;
      &:after {
        margin-left: -.8em;
      }
    }
  }
}



//Subscribe form
.subcribe-form {
  .btn {
    padding: 10px 20px;
  }

  input {
    padding: 12px 20px;
    width: 100%;
    color: $dark !important;
    border: none;
    outline: none !important;
    padding-right: 160px;
    padding-left: 30px;
    background-color: rgba($white, 0.8);
  }

  button {
    position: absolute;
    top: 1.5px;
    right: 2px;
    outline: none !important;
  }

  form {
    position: relative;
    max-width: 600px;
    margin: 0px auto;
  }
}

//Table
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  .table {

    td,
    th {
      vertical-align: middle;
    }

    &.table-nowrap {

      th,
      td {
        white-space: nowrap;
      }
    }
  }

  .table-center {
    th {
      vertical-align: middle !important;
    }

    tbody {
      tr {
        &:hover {
          color: $dark;
          background-color: $gray-100;
        }
      }
    }

    &.invoice-tb {

      th,
      td {
        text-align: end;
      }
    }
  }
}

// Loader Button

.ball {
  --size: 10px;
  --timing: 0.7s;
  --displace: 40px;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  background: #000;
  margin-right: var(--displace);
  animation: animation321 var(--timing) infinite alternate cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &.ball-sm {
    --size: 7px;
    --timing: 0.7s;
    --displace: 12px;
  }
}

@keyframes animation321 {
  0% {
    background: $primary;
    transform: translateX(var(--displace));
  }

  100% {
    background: $red;
    transform: translateX(00px);
  }
}

.loader {
              width: 44px;
              height: 44px;
              display: block;
              border: 1.5px solid $content ;
              border-radius: 50px ;
              position: relative;
              box-shadow: 0px 0px $primary-light inset;
              background-color: $primary-light;
              background-image: linear-gradient($primary 100px, transparent 0);
              background-position: 0  20px;
              background-size: 100% auto;
              background-repeat: no-repeat;
              box-sizing: border-box;
            }
            .loader::after {
              content: '';
              box-sizing: border-box;
              position: absolute;
              left: 50%;
              top: 0%;
              transform: translate(-50% , -95%);
              border: 2px solid $content;
              border-bottom: none;
              background: $content;
              width: 15px;
              height: 35px;
            }
            .loader::before {
              content: '';
              box-sizing: border-box;
              left: 50%;
              transform: translateX(-125%);
              bottom: -4px;
              border-radius: 50%;
              position: absolute;
              width: 6px;
              height: 6px;
              z-index: 10;
              animation: bubbles 3s linear infinite;
            }
            @keyframes bubbles {
              0% {
                box-shadow: 4px -10px rgba(255, 0, 0, 0), 6px 0px rgba(255, 0, 0, 0), 8px -15px rgba(255, 0, 0, 0), 12px 0px rgba(255, 0, 0, 0);
              }
              20% {
                box-shadow: 4px -20px rgba(255, 0, 0, 0), 8px -10px rgba(255, 0, 0, 0), 10px -30px rgba(255, 255, 255, 0.5), 15px -5px rgba(255, 0, 0, 0);
              }
              40% {
                box-shadow: 2px -40px rgba(255, 255, 255, 0.5), 8px -30px rgba(255, 0, 0, 0.4), 8px -60px rgba(255, 255, 255, 0.5), 12px -15px rgba(255, 255, 255, 0.5);
              }
              60% {
                box-shadow: 4px -60px rgba(255, 255, 255, 0.5), 6px -50px rgba(255, 0, 0, 0.4), 10px -90px rgba(255, 255, 255, 0.5), 15px -25px rgba(255, 255, 255, 0.5);
              }
              80% {
                box-shadow: 2px -80px rgba(255, 0, 0, 0.5), 4px -70px rgba(255, 0, 0, 0.4), 8px -120px rgba(255, 0, 0, 0), 12px -35px rgba(255, 0, 0, 0.5);
              }
              100% {
                box-shadow: 4px -100px rgba(255, 0, 0, 0), 8px -90px rgba(255, 0, 0, 0), 10px -120px rgba(255, 0, 0, 0), 15px -45px rgba(255, 0, 0, 0);
              }
            }

            //Spinner Logo Loader

            .preloader-wrapper {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffffff;
              opacity: 1;
            }
            
            .preloader {
            
              position: fixed;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              .spinner {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f9d4df;
                border-radius: 50%;
                width: 100px;
                height: 100px;
                .spin {
                  width: 100px; /* Resmin genişliği */
                  height: 100px; /* Resmin yüksekliği */
                  border-radius: 50%;
                  background-repeat: no-repeat;
                  background-image: url("https://www.sehrimbu.com/images/logo_2.png");
                  background-size: contain; /* Resmi genişliğe ve yüksekliğe sığdır */
                  background-position: center; /* Resmi ortala */
                  position: absolute;
                  left: 0; /* Sol ortadan sabitlenmesi için */
                  animation: directionMove 3s infinite linear;
                }
            
                .spin::before,
                .spin::after {
                  display: none;
                  content: "";
                  width: inherit;
                  height: inherit;
                  border: inherit;
                  border-radius: inherit;
                }
            
                @keyframes rotation {
                  from {
                    transform: rotate(0deg);
                  }
                  to {
                    transform: rotate(-360deg);
                  }
                }
              }
            }
            
            @keyframes directionMove {
              0%, 100% { transform: translate(0, 0); }
              20% { transform: translate(0, -20px); } /* Yukarı */
              40% { transform: translate(0, 20px); }  /* Aşağı */
              60% { transform: translate(-20px, 0); } /* Sola */
              80% { transform: translate(20px, 0); }  /* Sağa */
            }
    

//back-to-home
.back-to-home {
  position: fixed;
  top: 4%;
  right: 2%;
  z-index: 1;
}

//Sticky Sidebar
.sticky-bar {
  position: sticky;
  top: 80px;
}

//Feather Icon
.fea {
  stroke-width: 1.8;

  &.icon-sm {
    height: 16px;
    width: 16px;
  }

  &.icon-ex-md {
    height: 20px;
    width: 20px;
  }

  &.icon-m-md {
    height: 28px;
    width: 28px;
  }

  &.icon-md {
    height: 35px;
    width: 35px;
  }

  &.icon-lg {
    height: 42px;
    width: 42px;
  }

  &.icon-ex-lg {
    height: 52px;
    width: 52px;
  }
}

.uim-svg {
  vertical-align: 0 !important;
}

@media (max-width: 768px) {
  .display-1 {
    font-size: 56px !important;
  }

  .display-2 {
    font-size: 50px !important;
  }

  .display-3 {
    font-size: 42px !important;
  }

  .display-4 {
    font-size: 36px !important;
  }
}

.create-store-img {
  width: 100%;
  min-height: 230px;
  max-height: 230px;
  position: relative;
  text-align: center;
  border-radius: 6px;
  background-color: #fff;

}

.create-store-input {
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}


.create-store-profile {
  width: 100%;
  min-height: 100px;
  position: relative;
  text-align: center;
  background-color: #fff;


  .form-file-control {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }


}

.form-control-file {
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}


//Top Banner Component

.top-banner-container {
  width: 100%;
  height: 100%;
  background-color: $primary-light;

  .top-banner-wrapper {
    .top-banner-text {
      display: flex;
      justify-content: center;

      span {
        position: relative;
        top: 7px;
      }
    }

    .top-banner-d-none {
      display: flex;
      justify-content: flex-end;
      position: relative;
      bottom: 10px;
      right: 10px;
    }
  }
}

.top-banner-closed {
  border-bottom: 3px solid $primary;
}

// Accordion Filter Components

.accordion-filter-arrow {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .show {
    padding: 0 !important;
  }
}

//Carousel 

.rec-carousel {
  .rec-arrow {
    background-color: transparent !important;
    border: none !important;
    width: 0;
    height: 0;

  }

}

// Page Toolbar
.page-toolbar {
  background-image: linear-gradient(#F9FAFE, #F4F8FB);
  border-radius: 20px;
  width: 100%;
}

//Badges
.badge {
  padding: 5px 10px;
  font-weight: 600;
  align-items: center;
  border-radius: 5px;
  align-self: center;
  // width: 100px;
  height: 40px;

  &.badge-sm {
    padding: 7px 10px;
  }

  &.badge-icon {
    width: 50px;
    height: 40px;
  }

  &.badge-md {
    padding: 7px 14px;
  }

  &.badge-lg {
    padding: 10px 20px;
  }

  &.badge-xl {
    padding: 10px 35px;
  }

  &.badge-severity {
    max-width: 34px;
    height: 25.5px;
    padding: 5px;
  }

  &.badge-circle {
    width: 40px;
    height: 40px;
  }

  &.badge-link {
    &:hover {
      color: $white !important;
    }
  }
}

//Badges
@each $name,
$value in $colors {
  .badge-#{$name} {
    background-color: #{$value} !important;
    color: $white;

    &:hover {
      background-color: darken($value, 10%) !important;
    }
  }

  .badge-outline-#{$name} {
    background-color: transparent !important;
    color: #{$value} !important;
    border: 1px solid #{$value} !important;

    &:hover {
      background-color: #{$value} !important;
      color: $white !important;
      border: 1px solid #{$value} !important;
    }
  }
}

.badge-pills {
  border-radius: 20px;
}

.badge-notification-pill {
  width: 20px;
  height: 20px;
  padding: 3px 5px;
  line-height: 12px;
}

//Topbar Search
.topbar-search {
  @media (max-width: 600px) {
    width: 100% !important;
  }


  .form-search {
    width: 100% !important;
    background-color: white !important;
    border: none;
    padding: 10px 24px 10px 65px;
    border-radius: 20px;
    background-image: url("https://www.sehrimbu.com/images/topbar-search.svg");
    background-position: 24px 9px;
    background-repeat: no-repeat;
    border: 1px solid #f9d4df;

    @media (max-width: 600px) {
      width: 100% !important;
    }

    &::-webkit-input-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &::-moz-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &:-ms-input-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }

    &:-moz-placeholder {
      font-size: 14px;
      font-weight: 300;
      color: #797979;
    }
  }


  .form-control {
    line-height: 0px !important;
  }
}

.pages-top {

  .sort-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 740px){
        flex-wrap: wrap;
        gap: 14px;
    }
  }

  .form-select {
    width: 200px;
    background-color: #f9d4df !important;
    border: none !important;
    height: 40px;
    line-height: 15px !important;
    font-size: 14px;
    font-weight: 300;

  }

}


//Animation Minus Icon

.minus-button {
  position: relative;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 30px;
  top: 16px;
  transform: rotate(45deg);

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: rgb(61, 61, 61);
    transition: transform 0.25s ease-out;
  }

  /* Vertical line */
  &:before {
    top: 0;
    left: 50%;
    width: 3px;
    height: 100%;
    margin-left: -2px;
    border-radius: 50px !important;
  }

  /* horizontal line */
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    margin-top: -2px;
    border-radius: 50px !important;

  }

  &:hover {
    cursor: pointer;

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(135deg);
    }
  }

  &.minus-md {
    width: 20px;
    height: 20px;
    right: 0px;

  }
}

.navbar-mobile {
  .minus-button {
    width: 20px;
    height: 20px;
  }
}


//Notification

.notification-error{
  border-radius: 10px !important;
  height: 100px !important;
  width: 350px !important;
  right: 54px !important;
  box-shadow: none !important;
  color: #000000 !important;
  background-color: rgba(230, 144, 5, 0.5) !important;
  backdrop-filter: blur(6px) !important;
  border:  none !important;
  .notification-dismiss{
    background-color: black !important;
    color: #DBDCE2 !important;
  }
.square {
  position: relative;
  height: 3px;
}

.left,
.center,
.right {
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgb(220, 220, 220);
}

.left,
.right { width: 20px }

.left { border-radius: 20px 0 0 20px }
.right { border-radius: 0 20px 20px 0 }

.center {
  
  left: 20px;
  width: 2.2px;
  transform: scaleX(60);
  transform-origin: left;
}

.right {
  transform: translateX(80px);
}

.center {
  animation: center-animate 5s ease infinite alternate;
}

.right {
  animation: left-animate 5s ease infinite alternate;
}

@keyframes left-animate {
  0% { transform: translateX(200px) }
  100% { transform: translateX(20px) }
}

@keyframes center-animate {
  0% { transform: scaleX(121) }
  100% { transform: scaleX(1) }
}
.square {
  position: absolute;
  left: calc(50% - 149px);
  top: calc(50% - -30px)
}
}


.notification-success {
  border-radius: 10px !important;
  height: 100px !important;
  width: 350px !important;
  right: 54px !important;
  box-shadow: none !important;
  color: $primary !important;
  background-color:  rgba(89, 206, 143, 0.3) !important;
  backdrop-filter: blur(6px) !important;
 font-weight: 500 !important;
  .notification-dismiss{
    background-color: black !important;
    color: #DBDCE2 !important;
  }
.square {
  position: relative;
  height: 3px;
}

.left,
.center,
.right {
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgb(220, 220, 220);
}

.left,
.right { width: 20px }

.left { border-radius: 20px 0 0 20px }
.right { border-radius: 0 20px 20px 0 }

.center {
  
  left: 20px;
  width: 2.2px;
  transform: scaleX(60);
  transform-origin: left;
}

.right {
  transform: translateX(80px);
}

.center {
  animation: center-animate 5s ease infinite alternate;
}

.right {
  animation: left-animate 5s ease infinite alternate;
}

@keyframes left-animate {
  0% { transform: translateX(200px) }
  100% { transform: translateX(20px) }
}

@keyframes center-animate {
  0% { transform: scaleX(121) }
  100% { transform: scaleX(1) }
}
.square {
  position: absolute;
  left: calc(50% - 149px);
  top: calc(50% - -30px)
}
}

// Mobil Notification Checkmark

.mobil_checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke .6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  

}

.mobil_checkmark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .9s ease-in-out .9s both;
  
}

.mobil_checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;

}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
    
  }
}
.notification-modal-wrapper{
  background-image: url("https://tsignet.com:8000/api/media/public/icons/verify_badge.svg");
  background-size: 250px 150px !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

}

//Phone Input
.react-tel-input {
  .form-control {
    border: 1px solid #dee2e6 !important;
    font-size: 14px !important;
    line-height: 32px !important;
    background-color: #ffffff !important;
    border-radius: 20px !important;
    width: 100% !important;
    height: 47px !important;
  }
  .flag-dropdown{
    border-radius: 20px 0px 0px 20px !important;
    background-color: $white !important;
    border: 1px solid #dee2e6 !important;

  }
}

//Update Status

.update-condition-container {
  border-radius: 20px;
  color: $white;
  .update-condition-header {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 10px;
  }
}
//Section

.section {
  padding: 0px 0 90px 0;
  position: relative;
  margin-top: 1.5rem;
  @media (max-width: 768px) {
    padding: 60px 0;
  }
}

.section-tab {
  padding: 30px 0 90px 0;
  position: relative;
  @media (max-width: 768px) {
    padding: 30px 0 60px 0;
  }
}

//Material Table 
.material-table-toolbar {
  .toolbar-pagination {
    span {
      font-size: 18px !important;
      font-weight: 300;
      color: #9ea2b1;
    }
  }

}


.my_store{
  .MuiTableHead-root {
    th {
      font-weight: 600 !important;
      font-size: 14px !important;
      color: $content !important;
    }
  }
  .MuiToolbar-regular{
    display: none !important;
  }
}
.material-table__wrap {
  background: #F5F8FB;
  border-radius: 17px;
  padding: 40px 32px 40px 32px;

  @media (max-height: 768px) {
    padding: 30px 20px 30px 20px;
  }

  .MuiPaper-root {
    background: transparent !important;
    box-shadow: none !important;
    color: $content-light !important;
  }

  .MuiTableHead-root {
    th {
      font-weight: 700;
      font-size: 16px;
      color: $content-light;
    }
  }

  .MuiTableRow-root {
    min-height: 72px;
    color: $content-light;
    vertical-align: middle;

    &:nth-child(odd) {
      background-color: $white;
    }

    td {
      font-weight: 400;
      font-size: 16px;
      border: none;
    }
  }

  .MuiTableRow-footer {
    border: none !important;
    background-color: transparent !important;

    .pagination {
      .active-page {
        background-color: $primary !important;
        color: $white !important;
      }
      .page-link {
        border: 1px solid $muted !important;
        &:hover {
          background-color: $primary !important;
        }
      }
    }
  }
}

//Component
.star-rating{
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .on {
    color: #000;
  }
  .off {
    color: #ccc;
  }
}
section {
  min-height: 600px;

}

//General Add Basket Form

.general-add-basket-form {
    .css-1nmdiq5-menu{
    width: auto !important;
    }
    .react-select__control{
      border: 1px solid #E4E8EB !important;
      border-radius: 20px !important;
      width: 85px;
      margin-right: 3px;
      }
    .react-select__menu-list{
      z-index: 9999 !important;
      display: flex;
      width: 250px;
      flex-wrap: wrap;
      .react-select__option{
        width: 50px !important;
      }
    }
    .card {
      height: 420px !important;
    }
    .react-select__indicators{
      display: none !important;
    }
    @media only screen and (max-width: 762px) {
      .react-select__indicators{
        display: none !important;
      }
  
      .react-select__control{
        width: 75px !important;
      }
      .product-image-item{
        height: 300px !important;
      }
    }
  }
  
  // Hoverable Text

  @each $name,
$value in $colors {
  .text-hover-#{$name} {
    color: #{$value} !important;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      color: darken($value, 20%) !important;
    }
  }
  }

  // Google Login Button
  .google_login-container{
    border: 1px solid $primary;
    background-color: $white;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 10px;
    
  }


//Day Picker
.date-picker {
  background-color: $pink-lighter;
  border-radius: 20px;
}
.rdp-cell {
  .my-selected {
  background-color: $primary;
  color: $white;
  height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 14px;
}
  .rdp-day {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 14px;
  }
}

.flex-slick {
  .slick-track {
    display: flex;
    width: 100% !important;
  }
}

//Page Bread Crumbps

.breadcrumbs {
  display: flex;
  font-size: 14px; 
  font-weight: 300;
  color: #333; 
  transition: all 0.5s;
  margin-bottom: 8px;

  .home-icon {
  filter: drop-shadow(3px 3px 3px rgba(231,84,128,.5));
}
}

.breadcrumbItem {
  cursor: pointer;
  text-decoration: none;
  color: $primary-text-light;
  padding: 0 8px;
}

.breadcrumbItem:hover, .breadcrumbItem:focus {
  text-decoration: underline;
  color: $pink;
}

.breadcrumb-arrow {
  padding: 0 5px;
  color: #666;
}

