.product__comment-container {
    display: flex;
	flex-wrap: wrap;

}
.comment-buttons{
  @media (max-width: 720px){
    display: flex !important;
    flex-direction: column;
    gap: 1rem !important;
  }
}
.comment-user-info  {
	.comment__box-grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
   
	  }
  
	  
	  .comment__box-grid >div:nth-child(2) {
		grid-column: span 7;
	  }
	  @media (max-width: 1300px) {
		.comment__box-grid  {
			display: flex !important;
			flex-wrap: wrap !important;
		}
		}
	  
}

.comment-filter-option-container {
    display: flex;
    .comment-filter-option-wrapper{
        display: flex;
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

      }
      
      .comment-filter-option-wrapper input {
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        top: 0;
        left: 0;
        height: 33px;
        width: 50px !important;
        justify-content: center !important;
        align-items: center;
        background-color: transparent;
        border: 1px solid $muted;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 500;
         border-radius: 10px !important;
    
      }
    
      .comment-filter-option-wrapper input:checked ~ .checkmark {
       border: 1.5px solid $primary
       
      }
      
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      .comment-filter-option-wrapper input:checked ~ .checkmark:after {
        display: block;
      }
      
      .comment-filter-option-wrapper .checkmark:after {
        left: 2px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: none !important;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
}


.comment__infinity-section{
  max-height: 400px !important;
  overflow-y: scroll;
  height: 400px !important;
}
.comment__size-select{
  .react-select__control{
    width: 200px !important;
  } 
  .btn {
    width: 200px !important;
    height: 45px !important;
  }
}


.comment__info-header {
  @media (max-width: 576px){
    .size_sugesstion{
      width: 100% !important;
    }
    .comment__sort-container{
      width: 100% !important;

    }
  }
}

.comment__preview-modal-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    .comment-header {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .comment-user-info-main{
        display: flex;
    gap: 8px;
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    height: 16px;
      }
    }
    .comment-section {

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $content;

    }
    .size-info {
      display: flex;
      align-items: center;
      gap: 8px;
    }
}
.comment__image-preview{
  .image-container{
     border-radius: 20px 0px 0px 20px;
  }
}
@media (max-width: 992px) {
  .comment__image-preview{
    .image-container{
       border-radius: 20px 20px 0px 0px;
    }
  }
  
}

.comment_box-grid-container{
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 720px){
    display: block !important;
  }
}