.Typist {
  display: inline;
}

li.active>a {
  color: #2f55d4 !important;
}

.pagination .active a {
  color: #ffffff !important;
}

.buttonClassName {
  background-color: btn-outline-secondary;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.accordian-custom {
  border: none;
}

#mt-1 {
  margin-top: 5px !important;
}

#mb-1 {
  margin-top: 5px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.masonry-container {
  width: 100%;
  margin: auto;
}

.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
}

.tile {
  img {
    width: 100%;
  }
}

.indicator-inactive,
.indicator-active {
  height: 12px !important;
  width: 6px !important;
  border-radius: 4px;
  border: none;
  margin-left: 7px;
  margin-right: 7px;
}

.indicator-inactive {
  background-color: #2f55d4 !important;
  opacity: 0.6;
}

.indicator-active {
  background-color: #2f55d4 !important;
  transform: rotate(45deg);
}

.custom-owl-dots {
  margin-top: 10px;
  text-align: center;
}

.classForContainer {
  width: 36px;
  height: 36px;
  right: 30px;
  position: fixed;
  bottom: 30px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: rgb(223, 248, 246);
  padding: 5px;
  align-items: center;
  border-radius: 20px !important;
  outline: none;
  border: 0;

  &:hover {
    background-color: rgb(15, 130, 121) !important;

    svg {
      stroke: $white !important;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    overflow: hidden;
    stroke: rgb(15, 130, 121) !important;
  }
}

.classForTransition {
  right: 30px;
}

//team

.addNewMemberButton {
  text-decoration-line: none;
  color: black;
}

.addNewMemberButton:hover {
  color: white;
}

//Activity





//masonry

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.accordion {
  .card {
    .faq {
      >.active-link {
        color: $primary !important;
      }
    }
  }
}

.hover-border {
  .nav-link {

    &:focus,
    &.active,
    &:active {
      outline: none !important;
      border: transparent !important;
    }

    &:hover {
      border-color: transparent !important;
    }
  }
}

.custom-dots {
  .indicator-active {
    background-color: #2f55d4 !important;
  }
}

.custom-dots {
  .indicator-inactive {
    background-color: #6486e4 !important;
  }
}



[class^="uil-"],
[class*=" uil-"] {
  &:before {
    margin: 0;
  }
}

.footer-border-bottom {
  border-bottom: 1px solid #283353;
}

// Notification Panel
.notification-date {
  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #f2f2f3;
    position: absolute;
    top: 50%;
  }

  small {
    background-color: #fff;
    z-index: 9;
  }
}

// List Dot
.list-styled {
  li {
    &::before {
      content: "·";
      color: #404040;
      width: 0.9em;
      margin-left: 0em;
      margin-right: 2px;
      font-weight: 900;
    }
  }
}

.dashboard-card {
  .card-header {
    padding: 1.2rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
    background-color: #fff;

    .title {
      font-size: 18px !important;
    }

    .form-select {
      position: absolute;
      width: 150px;
      right: 1.2rem;
      top: 0.9rem;
    }
  }

  .card-body {
    overflow: hidden;

    height: 30rem;
    min-height: 30rem;
  }
}

.chart-card {
  .card-header {
    padding: 1.2rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
    background-color: #fff;

    .title {
      font-size: 18px !important;
    }

    .form-select {
      position: absolute;
      width: 150px;
      right: 1.2rem;
      top: 0.9rem;
    }
  }

  .card-body {
    overflow: hidden;

    height: 25rem;
    min-height: 25rem;
  }
}

.purchase-plan {
  .list-group-item {
    &.active {
      color: #212529;
      border: 2px solid $primary !important;
    }
  }

  .list-group-item {
    a {
      font-size: 14px;
      text-decoration: underline !important;
    }
  }
}

.input-group {
  input {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }
}

.activity-dropdown {
  background-color: #f8f9fa !important;
  color: #000 !important;
  border-color: #dee2e6 !important;
  font-size: 13px !important;


}

.activity-dropdown-item {
  font-size: 14px !important;
}



.c-pill {
  align-items: center;
  font-weight: bold;
  font-size: 11px;

  height: 90%;
  white-space: nowrap;
  width: auto;

  position: relative;
  border-radius: 100px;

  text-overflow: ellipsis;
  color: #ffffff;

  word-break: break-word;
}

.toggle {
  position: relative;
  display: inline;
}

.labell {
  position: absolute;
  color: #fff;
  font-weight: 600;

  pointer-events: none;
}

.onbtn {
  bottom: 0.5px;
  right: 3px;
}

.ofbtn {
  bottom: 0.5px;
  left: 2px;
}

.inputt[type="checkbox"] {
  position: relative;
  width: 2em;
  height: 1em;
  background-color: #ffffff;
  -webkit-appearance: none;
  border-radius: 20px;

  outline: none;

  border: 1px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.inputt:checked[type="checkbox"] {
  background: #2f55d4;
}

.inputt[type="checkbox"]::before {
  z-index: 2;
  position: absolute;
  padding-top: 1px;
  font-family: "";
  font-weight: 400;
  font-size: 7px;
  content: "";
  color: white;
  padding-top: 0.078rem;
  padding-left: 0.13rem;
  left: 1.3px;
  top: 1px;
  height: 12px;
  width: 12px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 2em;
  transition: 0.4s;
}

.inputt:checked[type="checkbox"]::before {
  left: 17px;
  background-color: white;
  bottom: 7px;
  content: "";
  font-family: "";
  font-weight: 400;
  color: #2f55d4;
}

.font-18 {
  font-size: 18px !important;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(247, 246, 246);
  border-radius: 5px;
}


.scrollbar::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 5px;
}

.widget-inner-scroll {
  max-height: 130px;
  overflow: hidden;

  &.scroll-active {
    max-height: 150px;
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgb(247, 246, 246);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #dee2e6;
      border-radius: 5px;
    }
  }
}





.top-login {
  cursor: pointer;
}

.top-login:hover {
  color: #A54AE3;
}



.footer-bg {
  background: $primary;
}

.slick-slide {
  padding-left: 10px;
}
@media (max-width: 960px){
  .slick-slide {
    padding-left: 0px;
  }
}
.slick-slider2 {
  overflow-x: none !important;
}

.populer-saller-card {
  border-radius: 20px;

  .populer-saller-cardheader {
    background-color: transparent;

  }

  .header-img {
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0px 0px;
  }

}

.infity-scroll-pagination {
  height: 100%;
  max-height: 700px;
  overflow-x: none !important;
  overflow-y: scroll;

}

.store-detail-product-list.infity-scroll-pagination {
  height: 800px;
  min-height: 800px;
  max-height: 800px;
  overflow-x: none !important;
  overflow-y: scroll;
}

.basket-dropdown-menu {
  .avatar {
    border-radius: 10px !important;

  }

}



.wizard-form {
  padding: 18px 80px 18px 80px !important;
}

@media only screen and (max-width: 762px) {
  .wizard-form {
    padding: 18px 15px 18px 15px !important;
  }

}

@media only screen and (max-width: 762px) {
  .create-program-responsive {
    padding-top: 1.5rem !important;
  }
}

//Product File Upload
.product-file-upload {
  .profile-avatar-upload {
    position: relative;
    overflow: hidden;

    &:before {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      border-radius: 0;
      opacity: 0.5;
      transition: 0.5s ease;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      background-color: $muted;
      color: #cebfe9;
    }

    span {
      font-size: 0;
    }

    &:hover {
      span {
        font-size: 14px;
        position: absolute;
        top: 120px;
        padding: 0;
        color: #C8BADC;
      }
    }

    &.profile-avatar-upload-sm {
      &:before {
        height: 28px;
      }
    }

    &:hover {
      &:before {
        font-size: 30px;
        height: 100%;
        background-color: $yellow-dark;
      }
    }

    .photo-upload {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
    }
  }
}

.credit-card-wrapper {

  .card-delete-icon {
    background-color: rgba(255, 255, 255, 0.6) !important;
    backdrop-filter: blur(6px);
    border-radius: 50px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    right: 0;
    margin-top: 10px;
    margin-right: 16px;
    font-size: 16px !important;
    opacity: 0;

  }

  .card-edit-icon {
    background-color: rgba(255, 255, 255, 0.6) !important;
    backdrop-filter: blur(6px);
    border-radius: 50px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    right: 0;
    margin-top: 45px;
    margin-right: 16px;
    font-size: 16px !important;
    opacity: 0;

  }

  &:hover {
    .card-delete-icon {
      opacity: 1;

    }

    .card-edit-icon {
      opacity: 1;
    }
  }
}

.product-create {
  .form-control {
    border-radius: 0px 50px 50px 0px !important;
  }
}

.rccs {
  width: 100% !important;

  .rccs__card {
    width: 100% !important;
  }
}

.favorite-product {
  .add-favorite-to-basket {
    border: 1px solid #E4E8EB !important;
  }

  .css-1nmdiq5-menu {
    width: auto !important;
  }

  .react-select__control {
    border: 1px solid #E4E8EB !important;
    border-radius: 20px !important;
    width: 84px !important;
  }

  .react-select__menu {
    z-index: 1500 !important;

  }

  .react-select__menu-list {
    z-index: 9999 !important;
    display: flex;
    width: 140px;
    flex-wrap: wrap;


  }

  .card {
    height: 420px !important;
  }

  .react-select__indicators {
    display: none !important;
  }

  @media only screen and (max-width: 762px) {

    .react-select__indicators {
      display: none !important;
    }

    .react-select__control {
      width: 75px !important;
    }

    .product-image-item {
      height: 300px !important;
    }
  }
}

@media only screen and (max-width: 500px) {
  .dashboard-button {
    margin-bottom: 10px;
  }
}

.form-disabled {
  background-color: #E9ECEF !important;
}

.btn-icon-table {
  width: 34px;
  height: 34px;
  font-size: 22px;
  border-radius: 4px;
  background-color: transparent;
  color: #A1ABA7 !important;
  transition: all 0.4s;
  overflow: hidden;
  z-index: 0;
  border: 0;
  justify-content: center;


  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: #002F6D !important;
  }
}

.hide__input-number-arrow {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.update__notification-store-image {
  position: absolute;
  right: 0;

  @media (max-width: 768px) {
    display: none !important;
  }
}

.particles-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.custom-btn-xl-width {
  width: 225px;
}

.order-evoluation {
  position: absolute;
  bottom: 0;
  right: 0;
}

#sticky-tab-menu {
  overflow: hidden scroll;
  position: sticky;
  z-index: 1;
  top: 0;
  transition: top 0.2s ease-in-out 0s;
  background-color: $white;
}

.price__guide-image {
  width: 50%;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.nav-items-width {
  width: 100%;

  @media only screen and (max-width: 992px) {
    width: 25%;
  }
}

.navbar-web {
  .dropdown-toggle::after {
    display: none !important;
  }
}

.store__earning-wrapper {
  background-color: #FFE8EF;
  border-radius: 20px 0px 0px 0px !important;
  border: none !important;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 20px;
}

.custom-table-filter {
  background-image: linear-gradient(#ffff, #F2F2F2);
  border-radius: 20px;

}

.custom-mockup-button {
  border-radius: 0px 0px 20px 20px !important;
  color: $primary-dark !important;
  background-color: #90fcb4;
  border: none;

  &:hover {
    background-color: $primary;
    color: #90fcb4 !important;

  }
}

.label-bold {
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-bottom: 0.5rem !important;
}

.overflow-hidden {
  overflow: hidden;
}

.settings {
  @media (max-width: 768px) {
    .card {
      width: 100% !important;
    }
  }

  .nav-link {
    width: 195px !important;
  }
}

@media (max-width: 992px) {
  .contact__us-vector {
    opacity: 0.5;
    position: absolute;
  }

  .contact__us-card {
    background-image: url('https://www.sehrimbu.com/images/contact_bg.svg');
    height: 720px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .login-form-input {
      background-color: rgba(255, 255, 255, 0.5) !important;
      backdrop-filter: blur(6px) !important;
    }
  }
}

.star-container {
  padding-right: 4px !important;
}

.no__stock-icon {
  color: $gray-500;
  opacity: 0.5;
  font-size: 24px;
  position: absolute;
  cursor: auto !important
}

.stock__check-modal {
  max-height: 500px;
  overflow-y: scroll;
}

.follow-delivery-button {
  font-size: 12px;
  font-weight: 500;
  border-radius: 20px;
  transition: 0.3s !important;

  &.follow-delivery-button_detail {
    background-color: $primary;
    color: $primary-light !important;

    &:hover {
      background-color: $primary-light;
      color: $primary !important;
    }
  }

  &.follow-delivery-button_refund_info {
    background-color: $primary-light;
    color: $primary !important;
  }

  &.follow-delivery-button_list {
    background-color: $primary-light;
    color: $primary !important;

    &:hover {
      background-color: $primary;
      color: $primary-light !important;
    }
  }

  &.follow-delivery-button_refund {
    background-color: $soft-red;
    color: $red !important;

    &:hover {
      background-color: $red;
      color: $white !important;
    }
  }

}




.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
//LOADING BASKET

.overlay-basket {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  /* Grimsi arka plan rengi (siyah ve opaklık) */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}


.loading-basket {
  background-color: transparent;
  /* Loading arka plan rengi */
  z-index: 1000;
  /* Diğer içeriklerin üzerinde kalması için daha yüksek z-index değeri */
}



.loading-ball p {
  margin-top: 10px;
}

.custom-padding {
  padding: 0.375rem 2.5rem !important;
}

.input-default-value {
  position: absolute;
  margin-left: 15px;
  margin-top: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: 'none';
  color: #000000;
}

.login__for-discount {
  background-color: #F4F8FB;
  &:hover {
    background-color: darken($color: #F4F8FB, $amount: 5);
    transition: 0.5s;
  }
}

.baseproduct__select-formsection{
  width: 42.5%;
}

@media only screen and (max-width: 762px) {
  .baseproduct__select-formsection{
    width: 100% !important
  }

}
@media only screen and (max-width: 996px) {
  .baseproduct__select-formsection{
    width: 62.5%;
  }

}
.ticket_card {
  border: 1px solid $pink-light;
 
}
.ticket_card_active {
  transition: all 0.5s;
  border: 1px solid $pink-light;
  &:hover{
    border: 1px solid $pink
  }
}
.tableSelected{
  background-color: $primary-light !important;
  border: 1px solid $primary !important;
  i {
    color: $primary !important;
  }
}
.tableCheckUnavailable{
  opacity: 0.30;
  cursor: default !important;
}

.slick-next{
  display: none !important;
}
.place__popular-activities{
  .slick-track {
    // width: 100% !important;
  }

}
.select__city-cardbody {
  width: 50%;
  border-right:  2px solid $pink-light;

  @media (max-width: 960px){
  width: 100%;
  border: none;
  }


}
.select__city-box{
  @media (max-width: 960px){
   margin:  0 !important;
    }
}

.place-comment-modal{
  .star-container{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.address-border-bottom{
  border-bottom: 1px solid #e9ecef;
}
.reservation-create-modal{
  .time-clocks{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .modal-header{
    align-items: flex-start;
    @media (max-width: 576px){
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0;
    }

  }
  .modal-header{
    button {
        position: absolute;
        right: 0;
        margin-top: -1rem;
        margin-right: 0rem;
    }
    
  }
}

.place-campaign-modal{
  .slick-slide{
    padding: 0 !important;
   
  }
  .shop-list{
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
  }
}
.place-campaign-header{
  position: absolute;
  z-index: 1001;
  right: 0;
  margin-right: 16px;
  margin-top: 16px;
  cursor: pointer;
  i{
    filter: drop-shadow(0.2rem 0.2rem 0.4rem rgba(231, 84, 128, 1));

  }
  
}