.cookie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 30%;
    margin: 0 !important;
    z-index: 1002;
    opacity: 0;
    visibility: hidden;
    border-radius:20px 0px 0px 0px;
    transform: translateX(100%);
    transition: all 500ms ease-out;
    color: $primary;
    font-weight: 600;
    background: rgba(9, 206, 143, 0.6);
    backdrop-filter: blur(5px);
    @media (max-width: 992px) {
        width: 100%;
        border-radius: 0px;

    }
    p{
        font-size: 15px;
        padding: 0px 20px;

        @media (max-width: 1200px) {
            font-size: 13px;
            padding: 0px 10px;
        }
    }

    button {
        width: 120px !important;
        height: 35px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        border-radius: 20px;
        border-color: #ecf0f1;
        background-color: #ecf0f1;
        color: #212327;
        border: none;

        @media (max-width: 1200px) {
            font-size: 13px !important;
        }
    }
    .btn-outline {
        background: transparent !important;
        border: 1px solid #ecf0f1 !important;
        color: #ecf0f1 !important;
    }
}

.cookie.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
    transition-delay: 1000ms;
}


