.guide-icon-container {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: $black;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    position: fixed;
    right: 0;
    bottom: 50%;
    z-index: 1;
    margin-right: 20px;
    margin-bottom: 120px;

    @media (max-width: 499px) {
        bottom: 0 !important;
        margin-bottom: 80px;
    }
}

.guide-menu {
    background-color: $white;
    position: fixed;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 33%;
    z-index: 1001;

    @media (max-width: 1200px) {
        width: 50%;
    }

    @media (max-width: 1025px) {
        width: 60%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    .guide-menu-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid $muted;
    }

    .guide-menu-body {
        overflow-y: scroll;
        max-height: 100%;

        .body-text {
            margin: 20px;
            line-height: 2;
            padding-bottom: 100px;

        }
    }
}

.guide-menu-body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.guide-menu-body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}