.sidebar {
    &.sticky-bar {
      background-color: transparent;
      padding: 0 !important;

    }
    &.rounded {
      border-radius: 0px !important;
    }
    &.shadow {
      box-shadow: none !important;
    }
  }
  
  .widget {
    padding-left: 22px;
    padding-bottom: 28px;
    border-radius: 10px;
    padding-right: 22px;
  
    .widget-title {
      font-size: 16px !important;
      font-weight: 600;
      color: $content;
    }
    .form-check {
      margin-top: 7px;
      input {
        width: 14px;
        height: 14px;
        border: 1px solid $content-light;
      }
  
      label {
        font-size: 14px !important;
        font-weight: 500;
        color: $content-light;
      }
    }
  
    .widget-inner-scroll {
      max-height: 130px;
      overflow: hidden;
  
      &.scroll-active {
        max-height: 150px;
        overflow-y: scroll;
        scrollbar-width: thin;
  
        &::-webkit-scrollbar {
          width: 4px;
        }
  
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgb(247, 246, 246);
          border-radius: 5px;
        }
  
        &::-webkit-scrollbar-thumb {
          background: #dee2e6;
          border-radius: 5px;
        }
      }
    }
  }
  
  .widget-inner-scroll {
    max-height: 130px;
    overflow: hidden;
  
    &.scroll-active {
      max-height: 150px;
      overflow-y: scroll;
      scrollbar-width: thin;
  
      &::-webkit-scrollbar {
        width: 4px;
      }
  
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(247, 246, 246); 
        border-radius: 5px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: #dee2e6; 
        border-radius: 5px;
      }
    }
  }