#sticky-aggregations {
    height: 100vh;
    overflow: hidden scroll;
    position: sticky;
    top: 0;
    transition: top 0.2s ease-in-out 0s;
}

.product-app {
    width: 100%;
    margin: 0 auto;

    .product-image-item {
        width: 250px;
        height: 300px;
    }

    .product-filter-container {
        background-color: $white !important;
        width: 100%;
        margin-right: 10px;
        border-radius: 10px;

        .product-color-container {

            .color-container {
                .color-wrapper {
                    display: flex;
                    position: relative;
                    padding-left: 15px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .color-wrapper input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 30px;
                    width: 30px !important;
                    border: 0.5px solid $muted;
                    border-radius: 50px !important;

                }

                .color-wrapper input:checked~.checkmark {
                    border: 2px solid #65ff4c
                }

                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                .color-wrapper input:checked~.checkmark:after {
                    display: block;
                }

                .color-wrapper .checkmark:after {
                    left: 2px;
                    top: 5px;
                    width: 5px;
                    height: 10px;
                    border: none !important;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

        }

    }

    @media (max-width: 576px) {
        .product-image-item {
            width: 105%;
            height: 220px;
        }

        .favorite-product-add {
            justify-content: center !important;
        }

        .product-verify {
            width: 105%;
            height: 100%;
        }

        .product-app-container {
            .card {
                width: 240px !important;
                height: 430px !important;
                overflow-y: none !important;
                overflow-x: none !important;

            }
        }

        .product-list-wrapper {
            padding-left: 0;

        }

        .favorite-false {
            width: 26px !important;
            height: 26px !important
        }

        .favorite-true {
            width: 26px !important;
            height: 26px !important;
        }

        .favorite-button-wrapper {
            right: 0 !important;
        }

    }

    .product-app-container {
        position: relative;
        transition: opacity 0.7s ease;
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 1;

        .product-result-content {
            display: flex;
            margin-top: 15px;

            .product-list-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;

                .product-list-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .product-edit-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 33px;
                        height: 33px;
                        background-color: rgba(239, 251, 250, 0.5);
                        color: $primary;
                        border-radius: 20px;
                        position: absolute;
                        right: 5px;
                        top: 5px;

                        &:hover {
                            background-color: $primary;
                            color: $white;
                        }
                    }

                    .product-delete-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 33px;
                        height: 33px;
                        background-color: rgba(255, 206, 210, 0.5);
                        color: $red;
                        border-radius: 20px;
                        position: absolute;
                        right: 5px;
                        top: 45px;

                        &:hover {
                            background-color: $red;
                            color: $white;
                        }
                    }

                    .card {
                        height: 410px;
                        border-radius: 10px;
                        width: 265.3px;
                        position: relative;
                        margin-bottom: 20px;
                        margin-right: 20px;

                        .card-children {
                            border: none !important;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            position: relative;
                            box-shadow: none;
                            border-radius: 3px;
                            cursor: pointer;
                            border-radius: 10px;
                            border: none;
                            transition: box-shadow 0.2s linear;

                            img {
                                border-radius: 10px 10px 0px 0px;
                            }

                            .product-down {


                                .product-description-container {
                                    height: 36px;

                                    .product-desctiption-container-text-two-lines {
                                        overflow: hidden;
                                        height: 25px;
                                        text-align: left;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 5;
                                        -webkit-box-orient: vertical;
                                        margin-bottom: -2px;
                                        color: $muted;

                                        .product-description {
                                            position: relative;
                                            line-height: normal;
                                            margin: 2px 0;
                                        }
                                    }

                                    .product-rating {
                                        margin-top: 5px;
                                        display: flex;
                                        align-items: flex-end;
                                    }

                                    .product-price {
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.product-detail-image-cardbody {
    padding: 2.5rem !important;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .image-cross-left,
    .image-cross-right {
        background-color: $primary-light;
        font-size: 14px !important;
        height: 35px;
        min-height: 35px;
        max-height: 35px;
        width: 35px;
        min-width: 35px;
        max-width: 35px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: darken($primary-light, 10%);
            color: #ffff;
        }
    }
}

.product-detail-info-cardbody {
    .product-info-container {
        .product-info-header {
            flex-wrap: wrap;
            display: flex;
            align-items: flex-end;
            margin-bottom: 10px;

            .store-name {
                font-size: 14px;
                font-weight: 600;
                padding-right: 5px;
            }

            .product-name {
                font-size: 14px;
                font-weight: 400;
                padding-right: 5px;
            }

            .product-description {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .rating-section {
            display: flex;
            align-items: flex-end;
            font-size: 16px;
            color: $muted;
        }

        .product-price-section {
            margin-top: 10px;

            .product-price {
                font-size: 26px;
                font-weight: 700;
            }
        }
    }

    .product-order-info-container {
        border-top: 1px solid $muted;
        margin-top: 30px;
        width: 320px;

        @media only screen and (max-width: 500px) {
            width: 100%;
        }

        .size-container {
            display: flex;
            margin-top: 20px;

            .size-wrapper {
                display: flex;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .size-wrapper input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }


            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 30px;
                width: 50px !important;
                background-color: transparent;
                border: 1px solid $muted;
                border-radius: 10px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 400;
            }

            .size-wrapper input:checked~.checkmark {
                border: 2px solid $primary
            }

            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            .size-wrapper input:checked~.checkmark:after {
                display: block;
            }

            .size-wrapper .checkmark:after {
                left: 2px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: none !important;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            .size-wrapper {
                .disabledCheckmark {
                    opacity: 0.3;
                    cursor: default !important;
                }
            }
        }

    }

    .product-rules-container {
        margin-top: 10px;
        border-top: 1px solid $muted
    }

}

.base-product-question {
    background-color: $primary-light;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-create {
    input[type=file] {
        width: 350px;
        max-width: 100%;
        color: #444;
        padding: 0px;
        border: none;
        font-size: 14px;
        font-weight: 600;
        background-clip: padding-box !important;
        background-color: #f8f9fa !important;
        border: 1px solid #dedede !important;
        border-radius: 20px !important;
        display: block;
    }

    input[type=file]::file-selector-button {
        margin-right: 20px;
        border: none;
        background: transparent;
        padding: 10px 10px;
        border-radius: 20px;
        color: #444;
        cursor: pointer;
        transition: background .2s ease-in-out;
        border-radius: 20px 0px 0px 20px;
    }

    input[type=file]::file-selector-button:hover {
        background: rgb(217, 216, 216);

    }
}

.favorite-button-wrapper {
    position: absolute;
    top: 5px;
    right: 0px;
    cursor: pointer;
    padding-right: 5px;
    z-index: 1000;


    .favorite-false {
        float: left;
        width: 33px;
        height: 33px;
        background: url("https://www.sehrimbu.com/images/favorite-false.svg") no-repeat;

        &:hover {
            background: url("https://www.sehrimbu.com/images/favorite-hover.svg") no-repeat;
            transition: 0.3s;
        }
    }

    .favorite-true {
        float: left;
        width: 33px;
        height: 33px;
        background: url("https://www.sehrimbu.com/images/favorite-true.svg") no-repeat;

        &:hover {
            background: url("https://www.sehrimbu.com/images/favorite-hover.svg") no-repeat;
            transition: 0.3s;
        }
    }
}

.filter-smallscrn-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
    width: 33%;
    height: 100%;
    backdrop-filter: blur(10px);
    overflow-y: auto !important;

    @media (max-width: 960px) {
        width: 55%;
    }

    .product-color-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 185px;
        margin: 15px 0 0 0;

        .color-container {
            max-height: 100%;
            display: flex;
            overflow-y: hidden;
            flex-wrap: wrap;

            .color-wrapper {
                display: flex;
                position: relative;
                padding-left: 15px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .color-wrapper input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 30px;
                width: 30px !important;
                border: 0.5px solid $muted;
                border-radius: 50px !important;

            }

            .color-wrapper input:checked~.checkmark {
                border: 2px solid #65ff4c
            }

            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            .color-wrapper input:checked~.checkmark:after {
                display: block;
            }

            .color-wrapper .checkmark:after {
                left: 2px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: none !important;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}





.coupon-info-container {
    background-color: $primary-light !important;
    height: 170px !important;
    border-radius: 20px;
    box-shadow: 0 15px 2px -2px $primary;
    border-collapse: collapse;

    .coupon-info-wrapper {
        overflow-y: hidden !important;
    }
}

.store-list {
    .card {
        height: 435px !important
    }

    .product-desctiption-container-text-two-lines {
        height: 100px !important
    }
}


.product-verify {
    width: 100%;
    position: absolute;
    padding: 0px;
    background-color: transparent;
    border-radius: 20px;
    z-index: 1;
    height: 100%;
    justify-content: center;

    &.waiting-verification {
        background-color: rgba(252, 200, 117, 0.1);
        backdrop-filter: blur(1px);

    }

    &.reject-verification {
        background-color: rgba(255, 91, 105, 0.1);
        backdrop-filter: blur(1px);
    }

}

.verify-icon {
    opacity: 1 !important;
}

.product-status-tab {
    .tabs {
        .nav-tabs {
            li {

                .nav-link.active,
                .nav-link.active:focus,
                .nav-link.active:hover {
                    color: $dark !important;
                }

                &.disabled .nav-link {
                    color: #999999;
                }
            }
        }

        &.tabs--bordered-bottom {
            .nav-tabs {
                .nav-link {
                    border-color: transparent;
                    padding-bottom: 14px;
                }

                .nav-link.active {

                    &,
                    &:focus,
                    &:hover {
                        border-bottom: none !important;
                        background-color: $primary-light !important;
                        border-radius: 7px !important;
                        color: $primary !important;
                    }
                }
            }
        }
    }
}

.product-status-tag {
    position: absolute;
    top: 0;
    left: 0;
}

.loader__wrapper-card {
    padding-right: 9rem;

    @media (max-width: 576px) {
        padding-right: 0rem !important;
    }
}

.loader__wrapper-card-product {
    padding-right: 3.7rem;

    @media (max-width: 576px) {
        padding-right: 0rem !important;
    }
}

.size_table_color {
    .width {
        width: 50px;
        height: 5px;
        background: #00ffff;
        position: absolute;
        margin-top: -10px;
        border-radius: 20px
    }

    .height {
        width: 50px;
        height: 5px;
        background: #c90505;
        position: absolute;
        margin-top: -10px;
        border-radius: 20px
    }

    .leg {
        width: 50px;
        height: 5px;
        background: #ffa91f;
        position: absolute;
        margin-top: -10px;
        border-radius: 20px
    }
}

.general__store-product-card {
    margin: 0 !important;
    margin-right: 5px !important;
}


.activity_page {
    .text-two-lines {
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical;
        color: $muted;
    }

    .store-down {
        padding-top: 6px !important;
        padding-left: 10px;
    }

    .product-list-wrapper {
        .card {
            width: 100% !important;

            .card-children {
                img {
                    width: 100%;
                }
            }

        }
    }
}

.place-detail {
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 8px;
    }

    .slick-dots {
        bottom: 25px !important;

        .slick-active {
            li {
                button {
                    background-color: $pink;
                }
            }
        }

        li {

            button {

                &::before {
                    color: $pink !important;

                    font-size: 16px;
                }
            }

        }
    }

    .slider-image-container {
        width: 100%;
        height: 450px;
        border-radius: 8px;
        position: relative;

        img {
            object-fit: cover;
            border-radius: 8px;
        }
    }
}

.activity_rules {
    .ul-scrolled-active {
        max-height: 3500px !important;
        padding-bottom: 100px;
        
    }

    .ul-scrolled {
        max-height: 200px;

    }

    ul {
        display: flex;
        flex-direction: column;
        grid-gap: 15px 0;
        gap: 15px 0;
        overflow: hidden;
        transition: all 0.5s;
    }

    li::marker {
        color: $pink;
    }

    li {
        font-size: 16px;
        font-weight: 500;
        color: $primary;
        position: relative;
        padding-left: 23px;

    }

    .add-more {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 160px;
        background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        pointer-events: none;
        margin-bottom: 24px;

        button {
            display: flex;
            align-items: center;
            border: 1px solid $gray-200;
            height: 38px;
            padding: 0 24px;
            border-radius: 19px;
            grid-gap: 0 3px;
            gap: 0 3px;
            font-size: 14px;
            font-weight: 500;
            color: $primary;
            background: $white;
            cursor: pointer;
            transition: .3s color;
            pointer-events: all;
        }
    }

}