//
// general.scss
//
/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}

/* Firefox */
html {
  scrollbar-width: none; /* Firefox için scrollbar'ı gizler */
}

/* Microsoft Edge ve Internet Explorer */
html {
  -ms-overflow-style: none; /* Edge ve IE için scrollbar'ı gizler */
}

/* Diğer tarayıcılar */
html {
  overflow: -moz-scrollbars-none; /* Diğer tarayıcılar için scrollbar'ı gizler */
  scrollbar-width: none; /* Diğer tarayıcılar için scrollbar'ı gizler */
  scrollbar-width: thin;
}







body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    color: $content;
    background-color: #fcfcfc !important; 
  }
  img {
    color:$content;
    font-size: 8px;
    font-weight: 400;
  }

  
  .font-primary {
    font-family: "Inter", sans-serif;
  }
  
  .font-secondary {
    font-family: "Ubuntu", sans-serif;
  }
  
  .font-tertiary {
    font-family: "Roboto", sans-serif;
  }
  
  .font-badge {
    font-family: 'Lobster', 'cursive';
  }
  
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-base;
    line-height: 1.5;
    font-weight: 700;
  }
  
  ::selection {
    background: rgba($primary, 0.9);
    color: $white;
  }
  
  a {
    text-decoration: none !important;
  }
  
  p {
    line-height: 1.6;
  }
  
  .text-underline {
    text-decoration: underline !important;
  }
  .font-size-10 {
    font-size: 10px !important;
  }
  .font-size-12 {
    font-size: 12px !important;
  }
  .font-size-13 {
    font-size: 13px !important;
  }
  .font-size-14 {
    font-size: 14px !important;
  }
  .font-size-13 {
    font-size: 13px !important;
  }
  .font-size-15 {
    font-size: 15px !important;
  }
  .font-size-16 {
    font-size: 16px !important;
  }
  .font-size-18 {
    font-size: 18px !important;
  }
  .font-size-20 {
    font-size: 20px !important;
  }
  .font-size-22 {
    font-size: 22px !important;
  }
  .font-size-24 {
    font-size: 24px !important;
  }
  .font-size-26 {
    font-size: 26px !important;
  }
  .font-size-28 {
    font-size: 28px !important;
  }
  .font-size-30 {
    font-size: 30px !important;
  }
  .font-size-36 {
    font-size: 36px !important;
  }
  .font-size-40 {
    font-size: 40px !important;
  }
  .font-size-60 {
    font-size: 60px !important;
  }
  .font-size-100 {
    font-size: 100px !important;
  }
  
  .font-weight-100 {
    font-weight: 100 !important;
  }
  .font-weight-200 {
    font-weight: 200 !important;
  }
  .font-weight-300 {
    font-weight: 300 !important;
  }
  .font-weight-400 {
    font-weight: 400 !important;
  }
  .font-weight-500 {
    font-weight: 500 !important;
  }
  .font-weight-600 {
    font-weight: 600 !important;
  }
  .font-weight-700 {
    font-weight: 700 !important;
  }
  .font-weight-800 {
    font-weight: 800 !important;
  }
  .font-weight-900 {
    font-weight: 900 !important;
  }
  
  .line-height-20 {
    line-height: 20px;
  }
  .line-height-22 {
    line-height: 22px;
  }
  .line-height-24 {
    line-height: 24px;
  }
  .line-height-26 {
    line-height: 26px;
  }
  .line-height-28 {
    line-height: 28px;
  }
  .line-height-30 {
    line-height: 30px;
  }
  .line-height-32 {
    line-height: 32px;
  }
  .line-height-34 {
    line-height: 34px;
  }
  .line-height-36 {
    line-height: 36px;
  }
  .line-height-38 {
    line-height: 38px;
  }
  .line-height-40 {
    line-height: 40px;
  }
  .border-radius-0 {
    border-radius: 0 !important;
  }
  .border-radius-5 {
    border-radius: 5px !important;
  }
  .border-radius-8 {
    border-radius: 8px;
  }
  .border-radius-10 {
    border-radius: 10px;
  }
  .border-radius-20 {
    border-radius: 20px;
  }
  .border-radius-30 {
    border-radius: 30px;
  }
  .border-radius-50 {
    border-radius: 50px;
  }
  .border-none {
    border: none !important;
  }
  
  .general-scroll-horizontal{
    ::-webkit-scrollbar {
      width: 0em;
      height: 0em;
    }
  }
  
  .w-65 {
    width: 65% !important;
  }

  .text-dark {
    color: #303538 !important;
  }

  .two-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
            line-clamp: 2; 
    -webkit-box-orient: vertical;
  }
  .one-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .cursor-pointer {
    cursor: pointer;
  }
  .shadow-none{
    box-shadow:  none !important;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  .sticky-card {
    position: sticky;
    top: 0;
  }
  .min-height-150{
    min-height: 150px !important;
  }
  .min-height-200{
    min-height: 200px !important;
  }
  .min-height-300{
    min-height: 300px !important;
  }
  .min-height-400{
    min-height: 400px !important;
  }
  .min-height-500{
    min-height: 500px !important;
  }
  .min-height-700{
    min-height: 700px !important;
  }
  .min-height-800{
    min-height: 800px !important;
  }

  .loading-gift {
    width: 100px;
    height: 100px;    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
  }
  .backdrop-filter {
    backdrop-filter: blur(20px);

  }
  .overflow-hidden {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }

  .height-sm{
    height: 150px
  }
  .height-md{
    height: 250px;
  }
  .height-lg {
    height: 350px;
  }
  .height-xl{
    height: 450px;
  }
  .height-xxl{
    height: 550px;
  }
  
  .pr-1{
    padding-right: 0.5rem;
  }
  .pr-2{
    padding-right: 1rem;
  }
  .pr-4{
    padding-right: 1.5rem;
  }
  .pr-5{
    padding-right: 2rem;
  }
  .pr-6{
    padding-right: 4rem;
  }
  
  .pl-1{
    padding-left: 0.5rem;
  }
  .pl-2{
    padding-left: 1rem;
  }
  .pl-4{
    padding-left: 1.5rem;
  }
  .pl-5{
    padding-left: 2rem;
  }
  
  .font-mobile-resize {
    @media (max-width: 576px) {
    font-size: 12px !important;
    }
  }
  .bg-none {
    background-color: transparent !important;
  }

  .width-fitcontent {
    width: fit-content;
  }

  .text-decoration-through {
    text-decoration: line-through;
  }

  .object-fit-cover{
    object-fit: cover;
  }
  .text-decoration-none {
    text-decoration: none;
  }
  .place-detail {
    .slick-list{
      border-radius: 8px !important;
    }
  }